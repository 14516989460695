import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    email: null,
    userId: null,
    accessToken: null,
    role:null,
}

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        login: (state, action) => {
            state.accessToken = action.payload
            localStorage.setItem('access-token', action.payload);
        },
        setUserDetails:(state,action)=>{
            state.email = action.payload.email;
            state.userId=action.payload.userid;
            state.role = action.payload.role;
            localStorage.setItem('role', action.payload.role);
            localStorage.setItem('email', action.payload.email);


        },
        logout: (state) => {
            state.email = null;
            state.userId=null;
            state.accessToken = null;
            state.role = null;
            localStorage.clear();
        }
    }
})

export const { login, logout,setUserDetails } = userSlice.actions;

export const selectEmail = (state) => state.user.email;
export const selectUserId = (state) => state.user.userId;
export const selectAccessToken = (state) => state.user.accessToken;
export const selectRole = (state) => state.user.role;

export default userSlice.reducer;