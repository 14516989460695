import { configureStore } from '@reduxjs/toolkit';
import snackbarReducer from '../features/Toaster/toasterSlice';
import userSlice from '../features/User/userSlice';

export const store = configureStore({
  reducer: {
    toaster: snackbarReducer,
    user: userSlice
  },
});
