import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import axios from '../../api/axios';
import Loader from '../Loader';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function BarChart({ reportUuid, indexAxis, reportLabel }) {
    const [data, setData] = useState(null)
    const [loader, setLoader] = useState(true)
    const options = {
        indexAxis: indexAxis,
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        plugins: {
            legend: {
                position: 'right',
            },
            title: {
                display: true,
                text: reportLabel,
            },
        },
    }
    useEffect(() => {
        axios.get(`/report/${reportUuid}`)
            .then((response) => {
                setData({
                    labels: response.data.data.map((item) => {
                        return item.site_name;
                    }),
                    datasets: [
                        {
                            label: 'ranking',
                            data: response.data.data.map((item) => {
                                return item.normalized_ranking;
                            }),
                            borderColor: 'rgb(255, 99, 132)',
                            backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        }
                    ],
                })
            })
            .catch((error) => {

            })
            .finally(()=>setLoader(false))
    }, [])
    return (<>
    {loader?(<Loader/>) :data && (
            <Bar options={options} data={data} />
        )}
    </>);
}

export default BarChart