import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import axios from "../../api/axios";
import { useDispatch } from "react-redux";

import {
  KeyboardBackspace,
  SaveOutlined,
  SettingsOutlined,
} from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";

import { WhiteButton } from "../../components/Buttons";
import SubHeaderDivider from "../../components/SubHeaderDivider";
import {
  showErrorToast,
  showSuccessToast,
} from "../../features/Toaster/toasterSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

const statusCodeArray = [401, 403, 404, 409, 422];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

function CreateDevice() {
  const locationtoUse = useLocation();
  const [siteList, setSiteList] = useState([]);
  const navigate = useNavigate();
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const [deviceDataToUpdate, setDeviceDataToUpdate] = useState({
    deviceName: "",
    model: "",
    serialNumber: "",
    firmwareVersion: "",
    pvCapacity: "",
    batteryInstall: "",
    batteryPercentage: "",
    batteryDishargePer: "",
    batteryLossesPer: "",
    batteryUnitVoltage: "",
    modulesInstall: "",
    moduleSize: "",
    inverterDcvoltage: "",
    pollingInterval: "",
    siteUuid: "",
  });
  const createDeviceValidate = (values) => {
    console.log(values);
    let errors = {};
    if (!values.deviceName) {
      errors.deviceName = "Device Name is required";
    }
    if (!values.siteUuid) {
      errors.siteUuid = "Site is required";
    }

    if (!values.model) {
      errors.model = "Model required";
    }

    if (!values.serialNumber) {
      errors.serialNumber = "Serial Number required";
    } else if (isNaN(values.serialNumber)) {
      errors.serialNumber = "Serial Number should be Number";
    } else if (values.serialNumber.length < 4) {
      errors.serialNumber = "Serial Number should be 4 digit only";
    }

    if (!values.firmwareVersion) {
      errors.firmwareVersion = "Firmware required";
    } else if (isNaN(values.firmwareVersion)) {
      errors.firmwareVersion = "Firmware should be Number";
    }
    // if (!values.pvCapacity) {
    //     errors.pvCapacity = 'PV Capacity required'
    // }
    if (isNaN(values.pvCapacity)) {
      errors.pvCapacity = "PV Capacity should be Number";
    }

    // if (!values.batteryInstall) {
    //     errors.batteryInstall = 'Battery Install required'
    // }
    if (isNaN(values.batteryInstall)) {
      errors.batteryInstall = "Battery Install should be Number";
    }

    // if (!values.batteryPercentage) {
    //     errors.batteryPercentage = 'Battery Percentage required'
    // }
    if (isNaN(values.batteryPercentage)) {
      errors.batteryPercentage = "Battery Percentage should be Number";
    }
    // if (!values.batteryDishargePer) {
    //     errors.batteryDishargePer = 'Battery Discharge Percentage required'
    // }
    if (isNaN(values.batteryDishargePer)) {
      errors.batteryDishargePer =
        "Battery Discharge Percentage should be Number";
    }
    // if (!values.batteryLossesPer) {
    //     errors.batteryLossesPer = 'Battery Loss Percentage required'
    // }
    if (isNaN(values.batteryLossesPer)) {
      errors.batteryLossesPer = "Battery Loss Percentage should be Number";
    }
    // if (!values.batteryUnitVoltage) {
    //     errors.batteryUnitVoltage = 'Battery Unit Voltage required'
    // }
    if (isNaN(values.batteryUnitVoltage)) {
      errors.batteryUnitVoltage = "Battery Unit Voltage should be Number";
    }
    // if (!values.modulesInstall) {
    //     errors.modulesInstall = 'Module Battery Install required'
    // }
    if (isNaN(values.modulesInstall)) {
      errors.modulesInstall = "Module Battery Install should be Number";
    }
    // if (!values.moduleSize) {
    //     errors.moduleSize = 'Module Size required'
    // }
    if (isNaN(values.moduleSize)) {
      errors.moduleSize = "Module Size should be Number";
    }
    // if (!values.inverterDcvoltage) {
    //     errors.inverterDcvoltage = 'Inverter DC Voltage required'
    // }

    // if (!values.pollingInterval) {
    //     errors.pollingInterval = 'Polling Interval required'
    // }

    return errors;
  };
  const [solarDeviceCapacityUuid, setSolarDeviceCapacityUuid] =
    useState(undefined);
  useEffect(() => {
    axios.get("/site/dropdown").then((response) => {
      setSiteList(response?.data);
    });
  }, []);

  useEffect(() => {
    if (locationtoUse.state.recordToUpdate !== undefined) {
      axios
        .get(`/device/${locationtoUse.state.recordToUpdate}`)
        .then((response) => {
          if (response.status === 200 && response.data !== undefined) {
            console.log(response.data);
            setDeviceDataToUpdate({
              deviceName: response.data.deviceName,
              model: response.data.model,
              siteUuid: response.data.siteUuid,
              serialNumber: response.data.serialNumber,
              firmwareVersion: response.data.firmwareVersion,
              pvCapacity: response.data.pvCapacity,
              batteryInstall: response.data.batteryInstall,
              batteryPercentage: response.data.batteryPercentage,
              batteryDishargePer: response.data.batteryDishargePer,
              batteryLossesPer: response.data.batteryLossesPer,
              batteryUnitVoltage: response.data.batteryUnitVoltage,
              modulesInstall: response.data.modulesInstall,
              moduleSize: response.data.moduleSize,
              inverterDcvoltage: response.data.inverterDcvoltage,
              pollingInterval: response.data.pollingInterval,
            });
            setSolarDeviceCapacityUuid(response.data.solarDeviceCapacityUuid);
          }
        })
        .catch((error) => {
          const isArray = Array.isArray(error.response.data.detail);
          let errorMessage;
          if (isArray) {
            errorMessage = error.response.data.detail[0].msg;
          } else {
            errorMessage = error.response.data.detail;
          }
          if (error.response.status === 403) {
            localStorage.clear();
            navigate(`/`);
          } else if (statusCodeArray.includes(error.response.status)) {
            dispatch(showErrorToast(errorMessage));
          } else {
            dispatch(showErrorToast("Something went wrong"));
          }
        });
    }
  }, [locationtoUse.state.recordToUpdate]);

  const dispatch = useDispatch();

  const createDeviceOnSubmit = (values, { resetForm }) => {
    setIsSubmittingForm(true);

    if (locationtoUse.state.recordToUpdate === undefined) {
      const _values = {
        ...values,
        pvCapacity: values.pvCapacity ? parseInt(values.pvCapacity) : null,
        batteryInstall: values.batteryInstall
          ? parseInt(values.batteryInstall)
          : null,
        batteryDishargePer: values.batteryDishargePer
          ? parseInt(values.batteryDishargePer)
          : null,
        batteryPercentage: values.batteryPercentage
          ? parseInt(values.batteryPercentage)
          : null,
        batteryLossesPer: values.batteryLossesPer
          ? parseInt(values.batteryLossesPer)
          : null,
        batteryUnitVoltage: values.batteryUnitVoltage
          ? parseInt(values.batteryUnitVoltage)
          : null,
        moduleSize: values.moduleSize ? parseInt(values.moduleSize) : null,
        modulesInstall: values.modulesInstall
          ? parseInt(values.modulesInstall)
          : null,
        inverterDcvoltage: values.inverterDcvoltage
          ? parseInt(values.inverterDcvoltage)
          : null,
        pollingInterval: values.pollingInterval
          ? parseInt(values.pollingInterval)
          : null,
      };
      axios
        .post("/device/", _values)
        .then((response) => {
          if (response.status === 201) {
            dispatch(showSuccessToast("Device added successfully"));
            navigate(`/home/device`);
            resetForm();
          }
        })
        .catch((error) => {
          const isArray = Array.isArray(error.response.data.detail);
          let errorMessage;
          if (isArray) {
            errorMessage = error.response.data.detail[0].msg;
          } else {
            errorMessage = error.response.data.detail;
          }
          if (error.response.status === 403) {
            localStorage.clear();
            navigate(`/`);
          } else if (statusCodeArray.includes(error.response.status)) {
            dispatch(showErrorToast(errorMessage));
          } else {
            dispatch(showErrorToast("Something went wrong"));
          }
        })
        .finally(() => {
          setIsSubmittingForm(false);
        });
    }
    if (locationtoUse.state.recordToUpdate !== undefined) {
      const valuesToSend = {
        deviceName: `${values.deviceName}`,
        model: `${values.model}`,
        serialNumber: values.serialNumber,
        firmwareVersion: values.firmwareVersion,
        pvCapacity: values.pvCapacity,
        batteryInstall: values.batteryInstall,
        batteryPercentage: values.batteryPercentage,
        batteryDishargePer: values.batteryDishargePer,
        batteryLossesPer: values.batteryLossesPer,
        batteryUnitVoltage: values.batteryUnitVoltage,
        modulesInstall: values.modulesInstall,
        moduleSize: values.moduleSize,
        inverterDcvoltage: values.inverterDcvoltage,
        pollingInterval: values.pollingInterval,
        siteUuid: values.siteUuid,
        id: locationtoUse.state.recordToUpdate,
        solarDeviceCapacityUuid: solarDeviceCapacityUuid,
      };
      const _valuesToSend = {
        ...valuesToSend,
        pvCapacity: values.pvCapacity ? parseInt(values.pvCapacity) : null,
        batteryInstall: values.batteryInstall
          ? parseInt(values.batteryInstall)
          : null,
        batteryDishargePer: values.batteryDishargePer
          ? parseInt(values.batteryDishargePer)
          : null,
        batteryPercentage: values.batteryPercentage
          ? parseInt(values.batteryPercentage)
          : null,
        batteryLossesPer: values.batteryLossesPer
          ? parseInt(values.batteryLossesPer)
          : null,
        batteryUnitVoltage: values.batteryUnitVoltage
          ? parseInt(values.batteryUnitVoltage)
          : null,
        moduleSize: values.moduleSize ? parseInt(values.moduleSize) : null,
        modulesInstall: values.modulesInstall
          ? parseInt(values.modulesInstall)
          : null,
        inverterDcvoltage: values.inverterDcvoltage
          ? parseInt(values.inverterDcvoltage)
          : null,
        pollingInterval: values.pollingInterval
          ? parseInt(values.pollingInterval)
          : null,
      };

      axios
        .put("/device", _valuesToSend, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          debugger;
          if (response.status === 204) {
            dispatch(showSuccessToast("Updated Device Successfully"));
            navigate(`/home/device`);
          }
        })
        .catch((error) => {
          debugger;
          const isArray = Array.isArray(error.response.data.detail);
          let errorMessage;
          if (isArray) {
            errorMessage = error.response.data.detail[0].msg;
          } else {
            errorMessage = error.response.data.detail;
          }
          if (error.response.status === 403) {
            localStorage.clear();
            navigate(`/`);
          } else if (statusCodeArray.includes(error.response.status)) {
            dispatch(showErrorToast(errorMessage));
          } else {
            dispatch(showErrorToast("Something went wrong"));
          }
        })
        .finally(() => {
          setIsSubmittingForm(false);
        });
    }
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={deviceDataToUpdate}
      validate={createDeviceValidate}
      onSubmit={createDeviceOnSubmit}
    >
      {(props) => (
        <Box
          pt={3}
          onSubmit={props.handleSubmit}
          onReset={props.handleReset}
          component="form"
          role="form"
        >
          <Card>
            <CardHeader
              title={
                locationtoUse.state.recordToUpdate === undefined
                  ? "Create Solar Device"
                  : "Edit Solar Device"
              }
            />
            <Divider />

            <CardContent>
              <Grid container direction="row" columnSpacing={5}>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <TextField
                    required
                    name="deviceName"
                    label="Enter Device Name"
                    variant="standard"
                    {...props.getFieldProps("deviceName")}
                    error={
                      props.touched.deviceName &&
                      Boolean(props.errors.deviceName)
                    }
                    helperText={
                      props.touched.deviceName &&
                      Boolean(props.errors.deviceName)
                        ? props.errors.deviceName
                        : "Please Enter Device Name"
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <FormControl required>
                    <InputLabel
                      variant="standard"
                      id="siteUuid"
                      error={
                        props.touched.siteUuid && Boolean(props.errors.siteUuid)
                      }
                    >
                      Site
                    </InputLabel>

                    <Select
                      name="siteUuid"
                      labelId="siteUuid"
                      variant="standard"
                      MenuProps={MenuProps}
                      {...props.getFieldProps("siteUuid")}
                      error={
                        props.touched.siteUuid && Boolean(props.errors.siteUuid)
                      }
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {siteList &&
                        siteList.map((item) => (
                          <MenuItem key={item?.id} value={item?.id}>
                            {item?.siteName}
                          </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText
                      error={
                        props.touched.siteUuid && Boolean(props.errors.siteUuid)
                      }
                      variant="standard"
                    >
                      {props.touched.siteUuid && Boolean(props.errors.siteUuid)
                        ? props.errors.siteUuid
                        : "Please Enter Site"}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Divider sx={{ my: 2 }} />
              <Grid container direction="row" columnSpacing={5} rowSpacing={3}>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <TextField
                    required
                    name="model"
                    label="Model"
                    variant="standard"
                    {...props.getFieldProps("model")}
                    error={props.touched.model && Boolean(props.errors.model)}
                    helperText={props.touched.model && props.errors.model}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <TextField
                    required
                    name="serialNumber"
                    label="Enter Serial Number"
                    variant="standard"
                    {...props.getFieldProps("serialNumber")}
                    error={
                      props.touched.serialNumber &&
                      Boolean(props.errors.serialNumber)
                    }
                    helperText={
                      props.touched.serialNumber &&
                      Boolean(props.errors.serialNumber)
                        ? props.errors.serialNumber
                        : "Please Enter Serial Number"
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <TextField
                    required
                    name="firmwareVersion"
                    label="Enter Firmware"
                    variant="standard"
                    {...props.getFieldProps("firmwareVersion")}
                    error={
                      props.touched.firmwareVersion &&
                      Boolean(props.errors.firmwareVersion)
                    }
                    helperText={
                      props.touched.firmwareVersion &&
                      Boolean(props.errors.firmwareVersion)
                        ? props.errors.firmwareVersion
                        : "Please Enter Firmware"
                    }
                  />
                </Grid>
              </Grid>
              <Divider sx={{ my: 2 }} />
              <SubHeaderDivider>Device Capacity</SubHeaderDivider>

              <Grid container direction="row" columnSpacing={5}>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <FormControl required>
                    <TextField
                      helperText={
                        props.touched.pvCapacity &&
                        Boolean(props.errors.pvCapacity)
                          ? props.errors.pvCapacity
                          : "Please Enter Pv Capacity"
                      }
                      // required
                      name="pvCapacity"
                      type="number"
                      label="Enter Pv Capacity"
                      variant="standard"
                      {...props.getFieldProps("pvCapacity")}
                      error={
                        props.touched.pvCapacity &&
                        Boolean(props.errors.pvCapacity)
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <FormControl required>
                    <TextField
                      helperText={
                        props.touched.batteryInstall &&
                        Boolean(props.errors.batteryInstall)
                          ? props.errors.batteryInstall
                          : "Please Enter Battery Install"
                      }
                      // required
                      type="number"
                      name="batteryInstall"
                      label="Enter Battery Install"
                      variant="standard"
                      {...props.getFieldProps("batteryInstall")}
                      error={
                        props.touched.batteryInstall &&
                        Boolean(props.errors.batteryInstall)
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <TextField
                    helperText={
                      props.touched.batteryPercentage &&
                      Boolean(props.errors.batteryPercentage)
                        ? props.errors.batteryPercentage
                        : "Please Enter Battery Percentage"
                    }
                    // required
                    name="batteryPercentage"
                    type="number"
                    label="Enter Battery Percentage"
                    variant="standard"
                    {...props.getFieldProps("batteryPercentage")}
                    error={
                      props.touched.batteryPercentage &&
                      Boolean(props.errors.batteryPercentage)
                    }
                  />
                </Grid>
              </Grid>
              <Divider sx={{ my: 2 }} />
              <Grid container direction="row" columnSpacing={5}>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <TextField
                    helperText={
                      props.touched.batteryDishargePer &&
                      Boolean(props.errors.batteryDishargePer)
                        ? props.errors.batteryDishargePer
                        : "Please Enter Battery Discharge Percentage"
                    }
                    // required
                    type="number"
                    name="batteryDishargePer"
                    label="Enter Battery Discharge Percentage"
                    variant="standard"
                    {...props.getFieldProps("batteryDishargePer")}
                    error={
                      props.touched.batteryDishargePer &&
                      Boolean(props.errors.batteryDishargePer)
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <TextField
                    helperText={
                      props.touched.batteryLossesPer &&
                      Boolean(props.errors.batteryLossesPer)
                        ? props.errors.batteryLossesPer
                        : "Please Enter Battery Loss Percentage"
                    }
                    // required
                    type="number"
                    name="batteryLossesPer"
                    label="Enter Battery Loss Percentage"
                    variant="standard"
                    {...props.getFieldProps("batteryLossesPer")}
                    error={
                      props.touched.batteryLossesPer &&
                      Boolean(props.errors.batteryLossesPer)
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <TextField
                    helperText={
                      props.touched.batteryUnitVoltage &&
                      Boolean(props.errors.batteryUnitVoltage)
                        ? props.errors.batteryUnitVoltage
                        : "Please Enter Battery Unit Voltage"
                    }
                    // required
                    type="number"
                    name="batteryUnitVoltage"
                    label="Enter Battery Unit Voltage"
                    variant="standard"
                    {...props.getFieldProps("batteryUnitVoltage")}
                    error={
                      props.touched.batteryUnitVoltage &&
                      Boolean(props.errors.batteryUnitVoltage)
                    }
                  />
                </Grid>
              </Grid>
              <Divider sx={{ my: 2 }} />
              <Grid container direction="row" columnSpacing={5}>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <TextField
                    helperText={
                      props.touched.modulesInstall &&
                      Boolean(props.errors.modulesInstall)
                        ? props.errors.modulesInstall
                        : "Please Enter Module Battery Install"
                    }
                    // required
                    type="number"
                    name="modulesInstall"
                    label="Enter Module Battery Install"
                    variant="standard"
                    {...props.getFieldProps("modulesInstall")}
                    error={
                      props.touched.modulesInstall &&
                      Boolean(props.errors.modulesInstall)
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <TextField
                    helperText={
                      props.touched.moduleSize &&
                      Boolean(props.errors.moduleSize)
                        ? props.errors.moduleSize
                        : "Please Enter Module Size"
                    }
                    // required
                    type="number"
                    name="moduleSize"
                    label="Enter Module Size"
                    variant="standard"
                    {...props.getFieldProps("moduleSize")}
                    error={
                      props.touched.moduleSize &&
                      Boolean(props.errors.moduleSize)
                    }
                  />
                </Grid>
              </Grid>
              <Divider sx={{ my: 2 }} />
              <Grid container direction="row" columnSpacing={5}>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <TextField
                    helperText={
                      props.touched.inverterDcvoltage &&
                      Boolean(props.errors.inverterDcvoltage)
                        ? props.errors.inverterDcvoltage
                        : "Please Enter Inverter DC Voltage"
                    }
                    // required
                    type="number"
                    name="inverterDcvoltage"
                    label="Enter Inverter DC Voltage"
                    variant="standard"
                    {...props.getFieldProps("inverterDcvoltage")}
                    error={
                      props.touched.inverterDcvoltage &&
                      Boolean(props.errors.inverterDcvoltage)
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <TextField
                    helperText={
                      props.touched.pollingInterval &&
                      Boolean(props.errors.pollingInterval)
                        ? props.errors.pollingInterval
                        : "Please Enter Polling Interval"
                    }
                    // required

                    name="pollingInterval"
                    label="Enter Polling Interval"
                    variant="standard"
                    {...props.getFieldProps("pollingInterval")}
                    error={
                      props.touched.pollingInterval &&
                      Boolean(props.errors.pollingInterval)
                    }
                  />
                </Grid>
              </Grid>
              <Divider sx={{ my: 2 }} />
            </CardContent>
            <CardActions>
              <Tooltip title="Back to the Company List">
                <WhiteButton
                  variant="contained"
                  color="inherit"
                  startIcon={<KeyboardBackspace />}
                  onClick={() => {
                    navigate(`/home/device`);
                  }}
                >
                  Back
                </WhiteButton>
              </Tooltip>
              <Tooltip title="Reset">
                <WhiteButton
                  variant="contained"
                  color="inherit"
                  startIcon={<SettingsOutlined />}
                  type="reset"
                >
                  Reset
                </WhiteButton>
              </Tooltip>
              <Tooltip
                title={
                  locationtoUse.state.recordToUpdate === undefined
                    ? "Click to Add Devie"
                    : "Click to Update Devie"
                }
              >
                <LoadingButton
                  variant="contained"
                  type="submit"
                  color="warning"
                  loading={isSubmittingForm}
                  loadingPosition="start"
                  startIcon={<SaveOutlined />}
                >
                  {locationtoUse.state.recordToUpdate === undefined
                    ? "Save"
                    : "Update"}
                </LoadingButton>
              </Tooltip>
            </CardActions>
          </Card>
        </Box>
      )}
    </Formik>
  );
}

export default CreateDevice;
