import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { styled } from '@mui/material/styles';


const GradientButton = styled(LoadingButton)`
    font-weight: 700 !important;
    border-radius: 0.5rem;
    background: ${props => props.theme.gradient.mainLinearGradient};
    box-shadow: ${props => props.theme.shadow.mainBoxShadow};
`
const WhiteButton = styled(Button)`
    background-color: white;
    text-transform: capitalize;
`

export {GradientButton,WhiteButton}