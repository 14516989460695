import {
  KeyboardBackspace,
  SaveOutlined,
  SettingsOutlined,
} from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Box,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import axios from "../../api/axios";
import { useDispatch } from "react-redux";
import { WhiteButton } from "../../components/Buttons";
import SubHeaderDivider from "../../components/SubHeaderDivider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  showErrorToast,
  showSuccessToast,
} from "../../features/Toaster/toasterSlice";
import { DatePicker } from "@mui/x-date-pickers";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

const imageFileRegex = /(^image)(\/)[a-zA-Z0-9_]*/;
const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
const statusCodeArray = [401, 403, 404, 409, 422];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

function CreateSite() {
  const [contactList, setContactList] = useState([]);
  const locationtoUse = useLocation();
  const navigate = useNavigate();
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [siteCapacityUUID, setSiteCapacityUUID] = useState(undefined);
  const [siteDataToUpdate, setSiteDataToUpdate] = useState({
    site_name: "",
    location: "",
    longitude: "",
    latitude: "",
    classification: "",
    activationdate: "",
    address: "",
    pv_capacity: "",
    battery_install: "",
    battery_capacity: "",
    panel_install: "",
    panel_capcity: "",
    contact_uuid: "",
    site_image: "",
    sun_hours: "",
    derating_float: "",
  });
  const createSiteValidate = (values) => {
    let errors = {};
    if (!values.site_name) {
      errors.site_name = "Site is required";
    }
    if (!values.location) {
      errors.location = "Location is required";
    }
    if (!values.latitude) {
      errors.latitude = "Latitude is required";
    }
    if (!values.longitude) {
      errors.longitude = "Logitude is required";
    }
    if (!values.classification) {
      errors.classification = "Classification is required";
    }
    if (!values.activationdate) {
      errors.activationdate = "Activation Date is required";
    }
    if (dateRegex.test(values.activationdate)) {
      errors.activationdate =
        "Enter Activation Date in the Date and Time format";
    }
    if (!values.address) {
      errors.address = "Address is required";
    }
    // if (!values.pv_capacity) {
    //   errors.pv_capacity = 'PV Capacity is required'
    // }
    if (values.pv_capacity && isNaN(values.pv_capacity)) {
      errors.pv_capacity = "Pv Capacity should be Number";
    }
    // if (!values.battery_install) {
    //   errors.battery_install = 'Battery Install is required'
    // }
    else if (values.battery_install && isNaN(values.battery_install)) {
      errors.battery_install = "Battery Install should be Number";
    }
    // if (!values.battery_capacity) {
    //   errors.battery_capacity = 'Battery Capacity is required'
    // }
    if (values.battery_capacity && isNaN(values.battery_capacity)) {
      errors.battery_capacity = "Battery Capacity should be Number";
    }
    // if (!values.panel_install) {
    //   errors.panel_install = 'Panel Install is required'
    // }
    if (values.panel_install && isNaN(values.panel_install)) {
      errors.panel_install = "Panel Install should be Number";
    }
    // if (!values.panel_capcity) {
    //   errors.panel_capcity = 'Panel Capacity is required'
    // }
    if (values.panel_capcity && isNaN(values.panel_capcity)) {
      errors.panel_capcity = "Panel Capacity should be Number";
    }
    if (!values.contact_uuid) {
      errors.contact_uuid = "Contact is required";
    }
    if (locationtoUse.state.recordToUpdate === undefined) {
      if (!values.site_image) {
        errors.site_image = "Site Image is required";
      } else if (!imageFileRegex.test(values.site_image?.type)) {
        errors.site_image = "Upload Image File only";
      }
    }
    if (!values.sun_hours) {
      errors.sun_hours = "Sun Hours are required";
    } else if (isNaN(values.derating_float)) {
      errors.derating_float = "Sun Hours should be Number";
    }
    if (!values.derating_float) {
      errors.derating_float = "DeRating is required";
    } else if (
      isNaN(values.derating_float) ||
      values.derating_float - Math.floor(values.derating_float) === 0
    ) {
      errors.derating_float = "DeRating should be Decimal Number";
    }

    return errors;
  };

  useEffect(() => {
    axios.get("/contact/dropdown").then((response) => {
      setContactList(response?.data);
    });
  }, []);
  const dispatch = useDispatch();

  useEffect(() => {
    if (locationtoUse.state.recordToUpdate !== undefined) {
      // testing
      // console.log(locationtoUse.state.recordToUpdate);

      axios
        .get(`/site/${locationtoUse.state.recordToUpdate}`)
        .then((response) => {
          if (response.status === 200 && response.data !== undefined) {
            setSiteDataToUpdate({
              site_name: response.data.siteName,
              location: response.data.location,
              longitude: response.data.longitude,
              latitude: response.data.latitude,
              classification: response.data.classification,
              activationdate: response.data.activationdate,
              address: response.data.address,
              pv_capacity: response.data.pvCapacity,
              battery_install: response.data.batteryInstall,
              battery_capacity: response.data.batteryCapacity,
              panel_install: response.data.panelInstall,
              panel_capcity: response.data.panelCapacity,
              contact_uuid: response.data.contactUuid,
              // site_image: response.data.,
              sun_hours: response.data.sunHours,
              derating_float: response.data.deratingFloat,
            });
            setSiteCapacityUUID(response.data?.sitecapacityUuid);
          }
        })
        .catch((error) => {
          const isArray = Array.isArray(error.response.data.detail);
          let errorMessage;
          if (isArray) {
            errorMessage = error.response.data.detail[0].msg;
          } else {
            errorMessage = error.response.data.detail;
          }
          if (error.response.status === 403) {
            localStorage.clear();
            navigate(`/`);
          } else if (statusCodeArray.includes(error.response.status)) {
            dispatch(showErrorToast(errorMessage));
          } else {
            dispatch(showErrorToast("Something went wrong"));
          }
        });
    }
  }, [locationtoUse.state.recordToUpdate]);

  const createSiteOnSubmit = (values, { resetForm }) => {
    const _values = {
      ...values,
      activationdate: new Date(values.activationdate).getDate(),
    };
    // testing
    console.log(_values);

    setIsSubmittingForm(true);
    if (locationtoUse.state.recordToUpdate === undefined) {
      axios
        .post("/site/", _values, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status === 201) {
            dispatch(showSuccessToast("Site created successfully"));
            navigate(`/home/site`);
            resetForm();
          }
        })
        .catch((error) => {
          const isArray = Array.isArray(error.response.data.detail);
          let errorMessage;
          if (isArray) {
            errorMessage = error.response.data.detail[0].msg;
          } else {
            errorMessage = error.response.data.detail;
          }
          if (error.response.status === 403) {
            localStorage.clear();
            navigate(`/`);
          } else if (statusCodeArray.includes(error.response.status)) {
            dispatch(showErrorToast(errorMessage));
          } else {
            dispatch(showErrorToast("Something went wrong"));
          }
        })
        .finally(() => {
          setIsSubmittingForm(false);
        });
    }
    if (locationtoUse.state.recordToUpdate !== undefined) {
      const valuesToSend = {
        id: locationtoUse.state.recordToUpdate,
        siteName: values.site_name,
        contactUuid: values.contact_uuid,
        location: values.location,
        longitude: values.longitude,
        latitude: values.latitude,
        classification: values.classification,
        activationdate: values.activationdate,
        address: values.address,
        sitecapacityUuid: siteCapacityUUID,
        pvCapacity: values.pv_capacity,
        batteryInstall: values.battery_install,
        batteryCapacity: values.battery_capacity,
        panelInstall: values.battery_capacity,
        panelCapacity: values.panel_capcity,
        deratingFloat: values.derating_float,
        sunHours: values.sun_hours,
      };

      const _valuesToSend = {
        ...valuesToSend,
        activationdate: new Date(values.activationdate).getDate(),
        sunHours: parseInt(values.sun_hours),
      };
      console.log(_valuesToSend);
      axios
        .put("/site/", _valuesToSend, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          debugger;
          if (response.status === 204) {
            dispatch(showSuccessToast("Updated Site Successfully"));
            navigate(`/home/site`);
          }
        })
        .catch((error) => {
          debugger;
          const isArray = Array.isArray(error.response.data.detail);
          let errorMessage;
          if (isArray) {
            errorMessage = error.response.data.detail[0].msg;
          } else {
            errorMessage = error.response.data.detail;
          }
          if (error.response.status === 403) {
            localStorage.clear();
            navigate(`/`);
          } else if (statusCodeArray.includes(error.response.status)) {
            dispatch(showErrorToast(errorMessage));
          } else {
            dispatch(showErrorToast("Something went wrong"));
          }
        })
        .finally(() => {
          setIsSubmittingForm(false);
        });
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={siteDataToUpdate}
      validate={createSiteValidate}
      onSubmit={createSiteOnSubmit}
    >
      {(props) => (
        <Box pt={3} onSubmit={props.handleSubmit} component="form" role="form">
          <Card>
            <CardHeader
              title={
                locationtoUse.state.recordToUpdate === undefined
                  ? "Create Site"
                  : "Edit Site"
              }
            />
            <Divider />
            <CardContent>
              <Grid container direction="row" columnSpacing={5}>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <FormControl required>
                    <InputLabel
                      variant="standard"
                      id="contact_uuid"
                      error={
                        props.touched.contact_uuid &&
                        Boolean(props.errors.contact_uuid)
                      }
                    >
                      Contact
                    </InputLabel>

                    <Select
                      name="contact_uuid"
                      value="default"
                      labelId="contact_uuid"
                      variant="standard"
                      MenuProps={MenuProps}
                      {...props.getFieldProps("contact_uuid")}
                      error={
                        props.touched.contact_uuid &&
                        Boolean(props.errors.contact_uuid)
                      }
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {contactList &&
                        contactList.map((item) => (
                          <MenuItem key={item?.id} value={item?.id}>
                            {item?.contactName}
                          </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText
                      variant="standard"
                      error={
                        props.touched.contact_uuid &&
                        Boolean(props.errors.contact_uuid)
                      }
                    >
                      {props.touched.contact_uuid &&
                      Boolean(props.errors.contact_uuid)
                        ? props.errors.contact_uuid
                        : "Please Select Contact"}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <TextField
                    required
                    name="site_name"
                    label="Enter Site"
                    variant="standard"
                    {...props.getFieldProps("site_name")}
                    error={
                      props.touched.site_name && Boolean(props.errors.site_name)
                    }
                    helperText={
                      props.touched.site_name && Boolean(props.errors.site_name)
                        ? props.errors.site_name
                        : "Please Enter Site"
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <FormControl required>
                    <InputLabel
                      variant="standard"
                      id="classification"
                      error={
                        props.touched.classification &&
                        Boolean(props.errors.classification)
                      }
                    >
                      Classification
                    </InputLabel>

                    <Select
                      name="classification"
                      value="default"
                      labelId="classification"
                      variant="standard"
                      MenuProps={MenuProps}
                      {...props.getFieldProps("classification")}
                      error={
                        props.touched.classification &&
                        Boolean(props.errors.classification)
                      }
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={"partner"}>PARTNER</MenuItem>
                      <MenuItem value={"distributor"}>DISTRIBUTOR</MenuItem>
                      <MenuItem value={"client"}>CLIENT</MenuItem>{" "}
                    </Select>
                    <FormHelperText
                      variant="standard"
                      error={
                        props.touched.classification &&
                        Boolean(props.errors.classification)
                      }
                    >
                      {props.touched.classification &&
                      Boolean(props.errors.classification)
                        ? props.errors.classification
                        : "Please Select Classification"}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Divider sx={{ my: 2 }} />
              <Grid container direction="row" columnSpacing={5} rowSpacing={3}>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <TextField
                    required
                    name="location"
                    label="Enter Location"
                    variant="standard"
                    {...props.getFieldProps("location")}
                    error={
                      props.touched.location && Boolean(props.errors.location)
                    }
                    helperText={
                      props.touched.location && Boolean(props.errors.location)
                        ? props.errors.location
                        : "Please Enter Location"
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <TextField
                    required
                    name="longitude"
                    label="Enter Longitude"
                    variant="standard"
                    {...props.getFieldProps("longitude")}
                    error={
                      props.touched.longitude && Boolean(props.errors.longitude)
                    }
                    helperText={
                      props.touched.longitude && Boolean(props.errors.longitude)
                        ? props.errors.longitude
                        : "Please Enter Longitude"
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <TextField
                    required
                    name="latitude"
                    label="Enter Latitude"
                    variant="standard"
                    {...props.getFieldProps("latitude")}
                    error={
                      props.touched.latitude && Boolean(props.errors.latitude)
                    }
                    helperText={
                      props.touched.latitude && Boolean(props.errors.latitude)
                        ? props.errors.latitude
                        : "Please Enter Latitude"
                    }
                  />
                </Grid>
              </Grid>
              <Divider sx={{ my: 2 }} />
              <Grid container direction="row" columnSpacing={5} rowSpacing={3}>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <FormControl>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Choose Activation Date"
                        name="activationdate"
                        value={props.values.activationdate}
                        onChange={(newValue) => {
                          console.log(newValue);
                          props.setFieldValue("activationdate", newValue?.$d);
                        }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            required
                            {...params}
                            error={
                              props.touched.activationdate &&
                              Boolean(props.errors.activationdate)
                            }
                            helperText={
                              props.touched.activationdate &&
                              Boolean(props.errors.activationdate)
                                ? props.errors.activationdate
                                : "Please Enter Activation Date"
                            }
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <TextField
                    required
                    type={"file"}
                    label="Site Image"
                    variant="standard"
                    name="site_image"
                    disabled={
                      locationtoUse.state.recordToUpdate === undefined
                        ? false
                        : true
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(event) => {
                      props.setFieldValue(
                        "site_image",
                        event.currentTarget.files[0]
                      );
                    }}
                    onBlur={props.handleBlur}
                    error={
                      props.touched.site_image &&
                      Boolean(props.errors.site_image)
                    }
                    helperText={
                      props.touched.site_image &&
                      Boolean(props.errors.site_image)
                        ? props.errors.site_image
                        : "Please Upload Site Image"
                    }
                  />
                </Grid>
              </Grid>
              <Divider sx={{ my: 2 }} />
              <Grid container direction="row" columnSpacing={5} rowSpacing={3}>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <TextField
                    required
                    name="address"
                    multiline
                    label="Enter Address"
                    variant="standard"
                    {...props.getFieldProps("address")}
                    error={
                      props.touched.address && Boolean(props.errors.address)
                    }
                    helperText={
                      props.touched.address && Boolean(props.errors.address)
                        ? props.errors.address
                        : "Please Enter Address"
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <TextField
                    required
                    name="sun_hours"
                    label="Enter Sun Hours"
                    variant="standard"
                    {...props.getFieldProps("sun_hours")}
                    error={
                      props.touched.sun_hours && Boolean(props.errors.sun_hours)
                    }
                    helperText={
                      props.touched.sun_hours && Boolean(props.errors.sun_hours)
                        ? props.errors.sun_hours
                        : "Please Enter Sun Hours"
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <TextField
                    required
                    name="derating_float"
                    label="Enter DeRating"
                    variant="standard"
                    {...props.getFieldProps("derating_float")}
                    error={
                      props.touched.derating_float &&
                      Boolean(props.errors.derating_float)
                    }
                    helperText={
                      props.touched.derating_float &&
                      Boolean(props.errors.derating_float)
                        ? props.errors.derating_float
                        : "Please Enter DeRating"
                    }
                  />
                </Grid>
              </Grid>
              <Divider sx={{ my: 2 }} />
              <SubHeaderDivider>Site Capacity</SubHeaderDivider>
            </CardContent>
            <CardContent>
              <Grid container direction="row" columnSpacing={5}>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <TextField
                    // required

                    name="pv_capacity"
                    label="Enter PV Capacity"
                    variant="standard"
                    {...props.getFieldProps("pv_capacity")}
                    error={
                      props.touched.pv_capacity &&
                      Boolean(props.errors.pv_capacity)
                    }
                    helperText={
                      props.touched.pv_capacity &&
                      Boolean(props.errors.pv_capacity)
                        ? props.errors.pv_capacity
                        : "Please Enter PV Capacity"
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <TextField
                    // required

                    name="battery_install"
                    label="Enter Battery Intall"
                    variant="standard"
                    {...props.getFieldProps("battery_install")}
                    error={
                      props.touched.battery_install &&
                      Boolean(props.errors.battery_install)
                    }
                    helperText={
                      props.touched.battery_install &&
                      Boolean(props.errors.battery_install)
                        ? props.errors.battery_install
                        : "Please Enter Battery Intall"
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <TextField
                    // required

                    name="battery_capacity"
                    label="Enter Battery Capacity"
                    variant="standard"
                    {...props.getFieldProps("battery_capacity")}
                    error={
                      props.touched.battery_capacity &&
                      Boolean(props.errors.battery_capacity)
                    }
                    helperText={
                      props.touched.battery_capacity &&
                      Boolean(props.errors.battery_capacity)
                        ? props.errors.battery_capacity
                        : "Please Enter Battery Capacity"
                    }
                  />
                </Grid>
              </Grid>
              <Divider sx={{ my: 2 }} />
              <Grid container direction="row" columnSpacing={5}>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <TextField
                    // required

                    name="panel_install"
                    label="Enter Panel Install"
                    variant="standard"
                    {...props.getFieldProps("panel_install")}
                    error={
                      props.touched.panel_install &&
                      Boolean(props.errors.panel_install)
                    }
                    helperText={
                      props.touched.panel_install &&
                      Boolean(props.errors.panel_install)
                        ? props.errors.panel_install
                        : "Please Enter Panel Install"
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <TextField
                    // required
                    name="panel_capcity"
                    label="Enter Panel Capacity"
                    variant="standard"
                    {...props.getFieldProps("panel_capcity")}
                    error={
                      props.touched.panel_capcity &&
                      Boolean(props.errors.panel_capcity)
                    }
                    helperText={
                      props.touched.panel_capcity &&
                      Boolean(props.errors.panel_capcity)
                        ? props.errors.panel_capcity
                        : "Please Enter Panel Capacity"
                    }
                  />
                </Grid>
              </Grid>
              <Divider sx={{ my: 2 }} />
            </CardContent>
            <CardActions>
              <Tooltip title="Back to the Company List">
                <WhiteButton
                  variant="contained"
                  color="inherit"
                  startIcon={<KeyboardBackspace />}
                  onClick={() => {
                    navigate(`/home/site`);
                  }}
                >
                  Back
                </WhiteButton>
              </Tooltip>
              <Tooltip title="Reset">
                <WhiteButton
                  variant="contained"
                  color="inherit"
                  startIcon={<SettingsOutlined />}
                  onClick={() => {
                    props.handleReset();
                    props.setFieldValue("activationdate", null);
                    props.setFieldValue(props.values.site_image.files[0], "");
                  }}
                >
                  Reset
                </WhiteButton>
              </Tooltip>
              <Tooltip
                title={
                  locationtoUse.state.recordToUpdate === undefined
                    ? "Click to Save Site"
                    : "Click to Update Site"
                }
              >
                <LoadingButton
                  variant="contained"
                  type="submit"
                  color="warning"
                  loading={isSubmittingForm}
                  loadingPosition="start"
                  startIcon={<SaveOutlined />}
                >
                  {locationtoUse.state.recordToUpdate === undefined
                    ? "Save"
                    : "Update"}
                </LoadingButton>
              </Tooltip>
            </CardActions>
          </Card>
        </Box>
      )}
    </Formik>
  );
}

export default CreateSite;
