import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import { roles } from "../../common/Menus";
import {
  BarChart,
  BatteryGuage,
  GuageChart,
  PieChart,
  ScatterChart,
  StackedBarChart,
  SmallWidget,
  MapComponent,
  Table,
  ClientDashboardWidget,
} from "../../components/DashboardWidgets";

function Index() {
  const navigate = useNavigate();
  const [dashboardList, setDashboardList] = useState(null);
  const [smallWidgetsList, setSmallWidgetsList] = useState(null);
  useEffect(() => {
    axios
      .post("/dashboard/")
      .then((response) => {
        const widgets = response?.data.data.filter((item) => {
          return item.componentType === "SmallWidgetComponent";
        });
        const dashboard = response?.data.data.filter((item) => {
          return item.componentType !== "SmallWidgetComponent";
        });
        setSmallWidgetsList(widgets);
        setDashboardList(dashboard);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          localStorage.clear();
          navigate(`/`);
        }
      });
  }, []);
  return (
    <Box pt={3}>
      {(dashboardList || smallWidgetsList) && (
        <>
          {localStorage.getItem("role") === roles.Client && dashboardList && (
            <>
              {dashboardList.map((item, index) => {
                return (
                  <ClientDashboardWidget
                    reportUuid={item.reportUuid}
                    key={index}
                  />
                );
              })}
            </>
          )}
          {localStorage.getItem("role") !== roles.Client && <SmallWidget />}
          {localStorage.getItem("role") !== roles.Client && dashboardList && (
            <Grid container direction="row" columnSpacing={5} rowSpacing={5}>
              {dashboardList.map((item, index) => {
                if (item.componentName === "chart") {
                  if (item.componentType === "HorizontalBarComponent")
                    return (
                      <Grid key={index} item md={6} sm={12} xs={12}>
                        <Card sx={{ maxHeight: 340, overflow: "auto" }}>
                          <CardHeader title={item.reportLabel} />
                          <Divider />
                          <CardContent>
                            <BarChart
                              reportLabel={item.reportLabel}
                              reportUuid={item.reportUuid}
                              indexAxis={"y"}
                            />
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  else if (item.componentType === "PieComponent")
                    return (
                      <Grid key={index} item md={6} sm={12} xs={12}>
                        <Card sx={{ maxHeight: 340, overflow: "auto" }}>
                          <CardHeader title={item.reportLabel} />
                          <Divider />
                          <CardContent>
                            <PieChart />
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  else if (item.componentType === "ScatterComponent")
                    return (
                      <Grid key={index} item md={6} sm={12} xs={12}>
                        <Card sx={{ maxHeight: 340, overflow: "auto" }}>
                          <CardHeader title={item.reportLabel} />
                          <Divider />
                          <CardContent>
                            <ScatterChart />
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  else if (item.componentType === "StackedBarComponent")
                    return (
                      <Grid key={index} item md={6} sm={12} xs={12}>
                        <Card sx={{ maxHeight: 340, overflow: "auto" }}>
                          <CardHeader title={item.reportLabel} />
                          <Divider />
                          <CardContent>
                            <StackedBarChart />
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                } else if (
                  item.componentName === "map" &&
                  item.componentType === "MapComponent"
                ) {
                  return (
                    <Grid key={index} item md={6} sm={12} xs={12}>
                      <Card sx={{ maxHeight: 340, overflow: "auto" }}>
                        <CardHeader title={item.reportLabel} />
                        <Divider />
                        <CardContent>
                          <MapComponent />
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                } else if (
                  item.componentName === "table" &&
                  item.componentType === "TableComponent"
                ) {
                  return (
                    <Grid key={index} item md={6} sm={12} xs={12}>
                      <Card sx={{ maxHeight: 340, overflow: "auto" }}>
                        <CardHeader title={item.reportLabel} />
                        <Divider />
                        <CardContent>
                          <Table
                            reportUuid={item.reportUuid}
                            fetchRealTimeData={item.fetchRealTimeData}
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                }
              })}
            </Grid>
          )}
        </>
      )}
    </Box>
  );
}

export default Index;
