import { createTheme } from '@mui/material';


const theme = createTheme({
    myColors: {
        white: '#FFF',
        orange: 'rgb(237, 100, 55)',
        darkBlue: '#1E1E2D',
        grey: '#F5F5F5',
        TextGrey: '#646c9a'
    },
    gradient: {
        mainLinearGradient: 'linear-gradient(195deg, rgb(241, 151, 73), rgb(232, 143, 26))',
    },
    shadow: {
        mainBoxShadow: 'rgb(0 0 0 / 14%) 0rem 0.25rem 1.25rem 0rem, rgb(212 25 0 / 40%) 0rem 0.4375rem 0.625rem -0.3125rem',
    },
})

export default theme

