import { Assessment, Contacts, CreateNewFolder, DashboardCustomize, Groups, History, LogoutRounded, NoteAdd, NotificationsActive, Place, SolarPower, Summarize } from "@mui/icons-material"
import AssignmentIcon from '@mui/icons-material/Assignment';
import LanguageSharpIcon from '@mui/icons-material/LanguageSharp';
import TuneSharpIcon from '@mui/icons-material/TuneSharp';
import NoteAddSharpIcon from '@mui/icons-material/NoteAddSharp';
export const roles = {
    Admin: 'ADMIN',
    Client: 'CLIENT', Partner: 'PARTNER', Distributor: 'DISTRIBUTOR'
}

const menuList = [
    {
        id: 1,
        name: 'Dashboard',
        icon: <Assessment />,
        url: 'dashboard',
        roles: [roles.Admin, roles.Client, roles.Distributor, roles.Partner]
    },
    {
        id: 2,
        name: 'Create',
        icon: <CreateNewFolder />,
        roles: [roles.Admin, roles.Distributor, roles.Partner],
        subMenus: [
            {
                id: 1,
                name: 'Client',
                icon: <Groups color="warning" />,
                url: 'company',
                roles: [roles.Admin, roles.Distributor, roles.Partner],
            },
            {
                id: 2,
                name: 'Contact',
                icon: <Contacts color="warning" />,
                url: 'contact',
                roles: [roles.Admin, roles.Distributor, roles.Partner],
            },
            {
                id: 3,
                name: 'Sites',
                icon: <Place color="warning" />,
                url: 'site',
                roles: [roles.Admin, roles.Distributor],
            },
            {
                id: 4,
                name: 'Devices',
                icon: <SolarPower color="warning" />,
                url: 'device',
                roles: [roles.Admin, roles.Distributor],
            }
        ]
    },
    {
        id: 3,
        name: 'Alerts',
        icon: <NotificationsActive />,
        url: 'alert',
        roles: [roles.Admin, roles.Client, roles.Distributor, roles.Partner],
    },
    {
        id: 4,
        name: 'Reports',
        icon: <Summarize />,
        roles: [roles.Admin, roles.Client, roles.Distributor, roles.Partner],
        subMenus: [
            {
                id: 1,
                name: 'Global Reports',
                icon: <LanguageSharpIcon color="warning" />,
                url: 'global-report',
                roles: [roles.Admin, roles.Client, roles.Distributor, roles.Partner],
            },
            {
                id: 2,
                name: 'Custom Reports',
                icon: <TuneSharpIcon color="warning" />,
                url: 'custom-report',
                roles: [roles.Admin, roles.Distributor, roles.Partner],
            },
            // {
            //     id: 3,
            //     name: 'Report Builder',
            //     icon: <NoteAddSharpIcon color="warning" />,
            //     url: 'report-builder',
            //     roles: [roles.Admin, roles.Distributor, roles.Partner],
            // },
        ]
    },
    {
        id: 5,
        name: 'History',
        icon: <History />,
        url: 'history',
        roles: [roles.Admin, roles.Client, roles.Distributor, roles.Partner],
    },
    {
        id: 6,
        name: 'Logout',
        icon: <LogoutRounded/>,
        url: '/',
        roles: [roles.Admin, roles.Client, roles.Distributor, roles.Partner]
    },
]

export default function getMenus(role) {
    return menuList.map((item) => {
        if (item.roles.includes(role)) {
            if (item.hasOwnProperty('subMenus')) {
               const subMenus = item?.subMenus?.filter((value) => {
                    return value?.roles?.includes(role)
                })
                return {  ...item ,subMenus: subMenus}
            }
            else{
                return{...item}
            }
        }
    }).filter(item => item)
}
