import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Formik } from "formik";
import {
  Box,
  Grid,
  TextField,
  Zoom,
  Tooltip,
  IconButton,
  InputAdornment,
} from "@mui/material";

import {
  showErrorToast,
  showSuccessToast,
} from "../../features/Toaster/toasterSlice";
import { GradientButton } from "../../components/Buttons";
import { CardWithHeader } from "../../components/Cards";
import { unAuthenticatedAxios } from "../../api/axios";
import { useLocation, useNavigate } from "react-router-dom";
import { logout } from "../../features/User/userSlice";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const emailRegex = /^(?=.{1,81}$)[\w.-]+@[\w.-]+\.\w{2,4}$/;

const changePasswordInitialValues = {
  email: "",
  password: "",
  confirmPassword: "",
};

const changePasswordValidate = (values) => {
  debugger;
  let errors = {};
  if (!values.email) {
    errors.email = "Required";
  } else if (!emailRegex.test(values.email)) {
    errors.email = "Invalid Email";
  }
  if (!values.password) {
    errors.password = "Required";
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = "Required";
  }
  if (
    values.confirmPassword &&
    values.password &&
    values.confirmPassword !== values.password
  ) {
    errors.confirmPassword = "Password and Confirm Password does not match";
  }
  // else if (!passwordRegex.test(values.password)) {
  //     errors.password = 'Invalid Password'
  // }
  return errors;
};

function ChangePassword() {
  const locationtoUse = useLocation();
  const [loadingButton, setLoadingButton] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const dispatch = useDispatch();
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    dispatch(logout());
  }, []);

  const navigate = useNavigate();

  const changePasswordOnSubmit = (values, { resetForm }) => {
    debugger;
    setLoadingButton(() => true);
    const valuesToSend = {
      email: `${values.email}`,
      password: `${values.password}`,
      confirmPassword: `${values.confirmPassword}`,
      forceChangePassword: true,
    };
    unAuthenticatedAxios
      .post("/auth/change_password", valuesToSend, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + locationtoUse.state.tokenToSend,
        },
      })
      .then((response) => {
        if (response?.status === 200) {
          dispatch(showSuccessToast("Password Changed Successfully"));
          resetForm();
          setTimeout(navigate(`/`), 5000);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          dispatch(showErrorToast(error.response.data.detail));
        } else if (error.response.status === 404) {
          dispatch(showErrorToast(error.response.data.detail));
        } else if (error.response.status === 422) {
          dispatch(showErrorToast(error.response.data.detail));
        } else if (error.response.status === 409) {
          dispatch(showErrorToast(error.response.data.detail));
        } else if (error.response.status === 403) {
          localStorage.clear();
          navigate(`/`);
        } else {
          dispatch(showErrorToast("Something went wrong"));
        }
      })
      .finally(() => {
        setLoadingButton(() => false);
      });
  };
  return (
    <Container>
      <Zoom in timeout={375}>
        <Box
          px={1}
          width="100%"
          height="100vh"
          mx="auto"
          position="relative"
          zIndex={2}
        >
          <Grid
            container
            spacing={1}
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
              <CardWithHeader title={"Change Password"}>
                <Formik
                  initialValues={changePasswordInitialValues}
                  validate={changePasswordValidate}
                  onSubmit={changePasswordOnSubmit}
                >
                  {(props) => (
                    <Box
                      pt={4}
                      pb={3}
                      px={3}
                      mt={10}
                      onSubmit={props.handleSubmit}
                      component="form"
                      role="form"
                    >
                      <Box mb={2}>
                        <TextField
                          variant="outlined"
                          name="email"
                          type="text"
                          label="Email"
                          fullWidth
                          {...props.getFieldProps("email")}
                          error={
                            props.touched.email && Boolean(props.errors.email)
                          }
                          helperText={props.touched.email && props.errors.email}
                        />
                      </Box>
                      <Box mb={2}>
                        <TextField
                          variant="outlined"
                          name="password"
                          type={showPassword ? "text" : "password"}
                          label="Password"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip title="Show/Hide Password">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                  >
                                    {showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </Tooltip>
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                          {...props.getFieldProps("password")}
                          error={
                            props.touched.password &&
                            Boolean(props.errors.password)
                          }
                          helperText={
                            props.touched.password && props.errors.password
                          }
                        />
                      </Box>
                      <Box mb={2}>
                        <TextField
                          variant="outlined"
                          name="confirmPassword"
                          type={showConfirmPassword ? "text" : "password"}
                          label="Confirm Password"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip title="Show/Hide Password">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowConfirmPassword}
                                  >
                                    {showConfirmPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </Tooltip>
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                          {...props.getFieldProps("confirmPassword")}
                          error={
                            props.touched.confirmPassword &&
                            Boolean(props.errors.confirmPassword)
                          }
                          helperText={
                            props.touched.confirmPassword &&
                            props.errors.confirmPassword
                          }
                        />
                      </Box>
                      <Box mt={4} mb={3}>
                        <GradientButton
                          loading={loadingButton}
                          loadingIndicator={
                            <Box color={"white"}>Authenticating...</Box>
                          }
                          variant="contained"
                          type="submit"
                          fullWidth
                        >
                          save
                        </GradientButton>
                      </Box>
                    </Box>
                  )}
                </Formik>
              </CardWithHeader>
            </Grid>
          </Grid>
        </Box>
      </Zoom>
    </Container>
  );
}

export default ChangePassword;

const Container = styled.main`
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.7);

  &:before {
    background: url("/images/signInBgImage.jpg") center center / cover;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
`;
