import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  styled,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CalendarTodaySharpIcon from "@mui/icons-material/CalendarTodaySharp";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import { useDispatch } from "react-redux";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import ReportRoundedIcon from "@mui/icons-material/ReportRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import { DataGrid } from "@mui/x-data-grid";
import {
  showErrorToast,
  showSuccessToast,
} from "../../features/Toaster/toasterSlice";

import axios from "../../api/axios";
import dayjs, { Dayjs } from "dayjs";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";

import dayjsPluginUTC from "dayjs-plugin-utc";

dayjs.extend(dayjsPluginUTC);

function Alert() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [valueOfDateRange, setValueOfDateRange] = React.useState("Today");
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const statusCodeArray = [401, 403, 404, 409, 422];

  const menuItemLists = [
    "Today",
    "YesterDay",
    "Last 24 Hours",
    "Last 7 Days",
    "Last 30 Days",
    "This Month",
    "Last Month",
    "Custom Range",
  ];
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [count, setCount] = useState([]);
  const dispatch = useDispatch();
  const [loadingPage, setLoadingPage] = useState(true);

  const callAlert = (dateRangeFilter) => {
    debugger;
    let startingDate = startDate;
    let endingDate = endDate;
    //"Today"
    if (dateRangeFilter === menuItemLists[0]) {
      startingDate = dayjs().utc();
      endingDate = dayjs().utc();
    }
    // "YesterDay"
    else if (dateRangeFilter === menuItemLists[1]) {
      startingDate = dayjs().utc().subtract(1, "day");
      endingDate = dayjs().utc().subtract(1, "day");
    }
    //"Last 24 Hours"
    else if (dateRangeFilter === menuItemLists[2]) {
      startingDate = dayjs().utc().subtract(1, "day");
      endingDate = dayjs().utc();
    }
    //"Last 7 Days"
    else if (dateRangeFilter === menuItemLists[3]) {
      startingDate = dayjs().utc().subtract(1, "week");
      endingDate = dayjs().utc();
    }
    //"Last 30 Days"
    else if (dateRangeFilter === menuItemLists[4]) {
      startingDate = dayjs().utc().subtract(1, "month");
      endingDate = dayjs().utc();
    }
    //"This Month"
    else if (dateRangeFilter === menuItemLists[5]) {
      const thisMonth = dayjs().utc();
      startingDate = thisMonth.subtract(thisMonth.$D - 1, "days");
      endingDate = dayjs().utc();
    }
    // "Last Month"
    else if (dateRangeFilter === menuItemLists[6]) {
      const lastMonth = dayjs().utc().subtract(1, "month");
      startingDate = lastMonth.subtract(lastMonth.$D - 1, "days");
      endingDate = lastMonth.add(
        lastMonth.daysInMonth() - lastMonth.$D,
        "days"
      );
    }
    //"Custom Range"
    if (dateRangeFilter === menuItemLists[7]) {
      startingDate = startDate;
      endingDate = endDate;
    } else {
      setStartDate("");
      setEndDate("");
    }
    debugger;
    const valuesToSend = {
      fromDate: `${startingDate.format("YYYY-MM-DD")}`,
      toDate: `${endingDate.format("YYYY-MM-DD")}`,
    };
    axios
      .post("/alert/", valuesToSend, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        let col = [];
        col = response?.data?.columns
          .map((item) => {
            if (item.value !== "alertlogId") {
              return {
                field: item.value,
                minWidth: 250,
                headerName: item.preety_name,
                disableColumnFilter: true,
                disableColumnMenu: true,
                headerClassName: "super-app-theme--header",
              };
            }
          })
          .filter((item) => item);

        setColumns(() => col);
        setRows(response?.data?.data);

        setCount(response?.data?.severity);
      })
      .catch((error) => {
        const isArray = Array.isArray(error.response.data.detail);
        let errorMessage;
        if (isArray) {
          errorMessage = error.response.data.detail[0].msg;
        } else {
          errorMessage = error.response.data.detail;
        }
        if (error.response.status === 403) {
          localStorage.clear();
          navigate(`/`);
        } else if (statusCodeArray.includes(error.response.status)) {
          dispatch(showErrorToast(errorMessage));
        } else {
          dispatch(showErrorToast("Something went wrong.Please try again!!"));
        }
      })
      .finally(() => {
        setLoadingPage(false);
      });
  };

  useEffect(() => {
    callAlert(menuItemLists[0]);
  }, []);

  return (
    <>
      {!loadingPage && (
        <>
          <Box pt={3}>
            <Card>
              <CardContent>
                <Grid container direction="row" columnSpacing={1}>
                  <Grid item lg={11} md={10} sm={12} xs={12}>
                    <Button
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                      name="valueOfDateRange"
                      variant="outlined"
                      sx={{
                        color: "rgb(48, 77, 159)",
                        backgroundColor: "white",
                        fontSize: "12px",
                        textTransform: "none",
                        fontWeight: 700,
                        fontFamily: "Roboto,'Helvetica Neue',sans-serif",
                      }}
                      value="default"
                    >
                      <CalendarTodaySharpIcon sx={{ marginRight: "7px" }} />{" "}
                      Date Range :{" "}
                      {valueOfDateRange && (
                        <>
                          {valueOfDateRange}
                          <ArrowDropDownOutlinedIcon />
                        </>
                      )}
                    </Button>
                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={() => handleClose()}
                    >
                      {menuItemLists &&
                        menuItemLists.map((item, index) => (
                          <MenuItemStyle
                            key={index}
                            value={item}
                            onClick={() => {
                              handleClose();
                              setValueOfDateRange(item);
                              if (item !== "Custom Range") {
                                callAlert(item);
                              }
                            }}
                            sx={{ width: 200 }}
                          >
                            {item}
                          </MenuItemStyle>
                        ))}
                    </Menu>
                  </Grid>

                  <Grid
                    item
                    lg={1}
                    md={2}
                    sm={12}
                    xs={12}
                    pt={{ sm: "1px", xs: "7px" }}
                    sx={{
                      display: { sm: "flex", xs: "flex" },
                      justifyContent: { sm: "flex-end", xs: "flex-end" },
                      alignItems: { sm: "flex-end", xs: "flex-end" },
                      marginTop: { sm: "1px", xs: "4px" },
                    }}
                  >
                    <ResetButton
                      variant="standard"
                      sx={{
                        backgroundColor: "rgb(237, 100, 55)",
                        color: "white",
                        textTransform: "none",
                      }}
                      onClick={() => {
                        setValueOfDateRange(menuItemLists[0]);
                        callAlert(menuItemLists[0]);
                      }}
                    >
                      Reset
                    </ResetButton>
                  </Grid>
                  {valueOfDateRange == "Custom Range" && (
                    <Grid
                      container
                      direction="row"
                      columnSpacing={{ sm: 2, lg: 12, md: 10, xs: 2 }}
                    >
                      <Grid
                        item
                        lg={3}
                        md={3}
                        sm={4}
                        xs={12}
                        pt={{ sm: "10px", xs: "10px" }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="Choose Start Date"
                            value={startDate}
                            onChange={(newValue) => {
                              setStartDate(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                helperText="Please choose Start Date"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        sm={4}
                        xs={12}
                        pt={{ sm: "10px", xs: "10px" }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="Choose End Date"
                            value={endDate}
                            onChange={(newValue) => {
                              setEndDate(newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                helperText="Please choose End Date"
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        sm={4}
                        xs={12}
                        pt={{ sm: "10px", xs: "10px" }}
                        sx={{
                          display: { sm: "flex", xs: "flex" },
                          justifyContent: { sm: "flex-end", xs: "flex-end" },
                          alignItems: { sm: "flex-end", xs: "flex-end" },
                        }}
                      >
                        <ResetButton
                          variant="contained"
                          sx={{ backgroundColor: "rgb(237, 100, 55)" }}
                          onClick={() => {
                            callAlert("Custom Range");
                          }}
                        >
                          <SearchRoundedIcon />
                        </ResetButton>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Box>

          <Box pt={3}>
            <Grid container direction="row" columnSpacing={4}>
              <Grid
                item
                lg={3}
                md={3}
                sm={12}
                xs={12}
                sx={{ marginBottom: { md: "20px", sm: "20px", xs: "20px" } }}
              >
                <CardStyleOuter
                  sx={{
                    width: 80,
                    background: "linear-gradient(60deg,#ef5350,#e53935)",
                  }}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ReportRoundedIcon sx={{ color: "white" }} />
                  </CardContent>
                </CardStyleOuter>
                <CardStyleInner>
                  <CardContentStyle>
                    <Grid>
                      <FormHelperText sx={{ fontSize: 15 }}>
                        Critical
                      </FormHelperText>
                      <FormHelperText
                        sx={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        {count.critical}
                      </FormHelperText>
                    </Grid>
                  </CardContentStyle>
                </CardStyleInner>
              </Grid>
              <Grid
                item
                lg={3}
                md={3}
                sm={12}
                xs={12}
                sx={{ marginBottom: { md: "20px", sm: "20px", xs: "20px" } }}
              >
                <CardStyleOuter
                  sx={{
                    width: 80,
                    background: "linear-gradient(60deg,#ffa726,#fb8c00)",
                  }}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <WarningRoundedIcon sx={{ color: "white" }} />
                  </CardContent>
                </CardStyleOuter>
                <CardStyleInner>
                  <CardContentStyle>
                    <Grid>
                      <FormHelperText sx={{ fontSize: 15 }}>
                        High
                      </FormHelperText>
                      <FormHelperText
                        sx={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        {count.high}
                      </FormHelperText>
                    </Grid>
                  </CardContentStyle>
                </CardStyleInner>
              </Grid>
              <Grid
                item
                lg={3}
                md={3}
                sm={12}
                xs={12}
                sx={{ marginBottom: { md: "20px", sm: "20px", xs: "20px" } }}
              >
                <CardStyleOuter
                  sx={{
                    width: 80,
                    background: "linear-gradient(60deg,#26c6da,#00acc1)",
                  }}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ErrorRoundedIcon sx={{ color: "white" }} />
                  </CardContent>
                </CardStyleOuter>
                <CardStyleInner>
                  <CardContentStyle>
                    <Grid>
                      <FormHelperText sx={{ fontSize: 15 }}>
                        Warning
                      </FormHelperText>
                      <FormHelperText
                        sx={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        {count.information}
                      </FormHelperText>
                    </Grid>
                  </CardContentStyle>
                </CardStyleInner>
              </Grid>
              <Grid
                item
                lg={3}
                md={3}
                sx={{ marginBottom: { md: "20px", sm: "20px", xs: "20px" } }}
                sm={12}
                xs={12}
              >
                <CardStyleOuter
                  sx={{
                    width: 80,
                    background: "linear-gradient(60deg,#66bb6a,#43a047)",
                  }}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ErrorRoundedIcon sx={{ color: "white" }} />
                  </CardContent>
                </CardStyleOuter>
                <CardStyleInner>
                  <CardContentStyle>
                    <Grid>
                      <FormHelperText sx={{ fontSize: 15 }}>Low</FormHelperText>
                      <FormHelperText
                        sx={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        {count.low}
                      </FormHelperText>
                    </Grid>
                  </CardContentStyle>
                </CardStyleInner>
              </Grid>
            </Grid>
          </Box>

          <Box pt={3}>
            <Card>
              <CardContent sx={{ width: "100%" }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={10}
                  rowsPerPageOptions={[10]}
                  autoHeight={true}
                  GridAlignment="right"
                />
              </CardContent>
            </Card>
          </Box>
        </>
      )}
      {loadingPage && <Loader />}
    </>
  );
}

export default Alert;

const MenuItemStyle = styled(MenuItem)`
  color: black;
  font-family: "Roboto,'Helvetica Neue',sans-serif";
`;

const ResetButton = styled(Button)`
  &:hover {
    background-color: rgb(237, 100, 55);
  }
`;

const CardStyleOuter = styled(Card)`
  width: 100;
  position: absolute;
  z-index: 200;
  margin-left: 15px;
`;

const CardStyleInner = styled(Card)`
  height: 100px;
  position: relative;
  margin-top: 20px;
`;

const CardContentStyle = styled(CardContent)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-top: 1;
`;
