import React from "react";
import "./App.css";

import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import CreateContact from "./pages/Contact/CreateContact";
import CreateCompany from "./pages/Company/CreateCompany";
import CreateDevice from "./pages/Devices/CreateDevice";
import CreateSite from "./pages/Sites/CreateSite";
import Sidebar from "./components/Sidebar/Sidebar";
import SignInAndSignUp from "./pages/Auth/SignInAndSignUp";
import ChangePassword from "./pages/Auth/ChangePassword";
import TableList from "./components/TableList/TableList";
import History from "./pages/History/History";
import Alert from "./pages/Alerts/Alert";
import GlobalReports from "./pages/Reports/GlobalReports";
import GlobalReportViewDetails from "./pages/Reports/GlobalReportViewDetails";
import CustomReport from "./pages/Reports/CustomReport";
import CustomReportViewDetails from "./pages/Reports/CustomReportViewDetails";
import ReportBuilder from "./pages/Reports/ReportBuilder";
import ReportBuilderViewDetails from "./pages/Reports/ReportBuilderViewDetails";
import Dashboard from "./pages/Dashboard/index";
import { Zoom } from "@mui/material";
import ViewDevice from "./pages/Devices/ViewDevice";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="changepassword" element={<ChangePassword />} />

        <Route path="/" element={<SignInAndSignUp />} />
        <Route
          path="home"
          element={<Sidebar logo={"/images/solarlight-header.png"} />}
        >
          <Route path={"dashboard"} element={<Dashboard />} />
          <Route
            path="company"
            element={
              <TableList
                requestUrl={"/company"}
                title={"Client List"}
                submissionRoute={"create"}
              />
            }
          />
          <Route path="company/create" element={<CreateCompany />} />
          <Route
            path="contact"
            element={
              <TableList
                requestUrl={"/contact"}
                title={"Contact List"}
                submissionRoute={"create"}
              />
            }
          />
          <Route path="contact/create" element={<CreateContact />} />
          <Route
            path="device"
            element={
              <TableList
                requestUrl={"/device"}
                title={"Solar Device List"}
                submissionRoute={"create"}
                isView={true}
              />
            }
          />
          <Route path="device/create" element={<CreateDevice />} />
          <Route
            path="site"
            element={
              <TableList
                requestUrl={"/site"}
                title={"Site List"}
                submissionRoute={"create"}
              />
            }
          />
          <Route path="site/create" element={<CreateSite />} />
          <Route path="history" element={<History />} />
          <Route path="alert" element={<Alert />} />
          <Route path="device/view" element={<ViewDevice />} />

          <Route path="global-report" element={<GlobalReports />} />
          <Route
            path="global-report-list"
            element={<GlobalReportViewDetails />}
          />
          <Route path="custom-report" element={<CustomReport />} />
          <Route
            path="custom-report-list"
            element={<CustomReportViewDetails />}
          />
          <Route path="report-builder" element={<ReportBuilder />} />
          <Route
            path="report-builder-list"
            element={<ReportBuilderViewDetails />}
          />
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <h1>Website is under Construction</h1>
              </main>
            }
          />
        </Route>
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <h1>Website is under Construction</h1>
            </main>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
