import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

function SideMenuList({ item, open }) {
  const [subMenuOpen, setSubMenuOpen] = useState(false)
  return (
    <ListItem disablePadding sx={{ display: 'block' }}>
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? 'initial' : 'center',
          px: 2.5,
        }}
        onClick={() => {
          if (item.subMenus) {
            setSubMenuOpen(!subMenuOpen);
          }

        }}
        component={!item.subMenus ? Link : undefined}
        to={!item.subMenus ? item.url : undefined}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : 'auto',
            justifyContent: 'center',
          }}
        >

          {item.icon}
        </ListItemIcon>
        <ListItemText primary={item.name} sx={{ opacity: open ? 1 : 0 }} />
        {open && item.subMenus && (subMenuOpen ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
      {
        open && item.subMenus && <Collapse in={subMenuOpen} timeout="auto" unmountOnExit>
          <List sx={{ marginLeft: 3 }}>
            {item.subMenus && item.subMenus.map((subMenuItem) => {
              return (
                <ListItemButton
                  key={subMenuItem.id}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                  component={Link}
                  to={subMenuItem.url}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >

                    {subMenuItem.icon}
                  </ListItemIcon>
                  <ListItemText primary={subMenuItem.name} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              )
            })}
          </List>
        </Collapse>
      }

    </ListItem>
  )
}

export default SideMenuList