import { Box, Button, Card, CardContent, CardHeader, Divider, styled } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from '../../api/axios';
import { showErrorToast } from "../../features/Toaster/toasterSlice";
import Loader from "../../components/Loader";


function GlobalReports() {


    const dispatch = useDispatch();
    const navigate=useNavigate();
    const [loadingPage, setLoadingPage] = useState(true)
    const [reportRows, setReportRows] = useState([]);
    const [reportCols, setReportCols] = useState([]);

    const statusCodeArray = [401, 403, 404, 409, 422]

    
    useEffect(() => {
        axios
            .get("/report/names?global_report=true")
            .then((response) => {
                let histcol = [];
                Object.keys(response?.data[0]).forEach((key) => {
                    if (key !== "id" ) {
                        histcol.push({
                            field: key, headerName: key, disableColumnFilter: false, disableColumnMenu: true,minWidth:350,

                        })
                    }
                })
                histcol.push({
                    field: 'Action', headerName: 'Action',  width: 150, renderCell: (cellValues) => {
                        return (
                            <Box
                                sx={{
                                    textAlign: "center",

                                }}
                            >
                                {cellValues.value}
                            </Box>
                        );
                    }
                })
                setReportCols(() => histcol);
                setReportRows(response?.data?.map((item) => {
                    return {
                        ...item, 'Action':
                            <Box pr={1} pl={1}>

                                <ViewButton variant='contained' sx={{
                                    backgroundColor: "white",
                                    color: "rgb(237, 100, 55)", borderRadius: "6%",
                                    textTransform: "none"
                                }
                                }
                                onClick={()=>{
                                    navigate(`/home/global-report-list`,{ state: { MoudleId:item.id} })                                    
                                    }}>
                                View
                                </ViewButton>

                            </Box>
                    }
                }))

            })
            .catch((error) => {
                const isArray = Array.isArray(error.response.data.detail);
                let errorMessage;
                if (isArray) {
                    errorMessage = error.response.data.detail[0].msg;
                }
                else {
                    errorMessage = error.response.data.detail;
                }
                if (error.response.status === 403) {
                    localStorage.clear();
                    navigate(`/`);
                }
                else if (statusCodeArray.includes(error.response.status)) {
                    dispatch(showErrorToast(errorMessage))
                }
                else {
                    dispatch(showErrorToast("Something went wrong.Please try again!!"))
                }
            })
            .finally(()=>{
                setLoadingPage(false)
              })
    }, []);

    return (
        <>
        {!loadingPage &&(
      <>
            <Box pt={3} >
                <Card>
                    <CardHeader title="Global Report List" />
                    <Divider />
                    <CardContent></CardContent>
                </Card>
            </Box>
            <Box pt={3} >
                <Card>
                    <CardContent>
                        <DataGrid
                        rows={reportRows}
                        columns={reportCols}
                        autoHeight={true}
                        GridAlignment="right">
                       
                            </DataGrid>
                    </CardContent>
                </Card>
            </Box>
        </>
        ) }
        {loadingPage && <Loader/>}
      </>
       
       
    )
}

export default GlobalReports

const ViewButton = styled(Button)`
    &:hover{
      background-color: rgba(0, 0, 0, 0) !important
      
    }
`