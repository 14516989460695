import { Box } from "@mui/material"
import { styled } from '@mui/material/styles';

const BackCard = styled(Box)`
    width:100%;
    height:100%;
    z-index:5;
    transform: rotateY(180deg);
    position:"absolute";
`
const FrontCard = styled(Box)`
    width:100%;
    height:100%;
    z-index:5;
    position: relative;
`

export {BackCard,FrontCard}