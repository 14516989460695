import { Box, Card, CardContent, Grid, styled } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import axios from "../../api/axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showErrorToast } from "../../features/Toaster/toasterSlice";
import { useState } from "react";
const statusCodeArray = [401, 403, 404, 409, 422];
const colorArray = [
  "rgb(243, 156, 18)",
  "rgb(35, 183, 229)",
  "rgb(46, 204, 113)",
  "rgb(231, 76, 60)",
];
function SmallWidget() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get("/dashboard/insights")
      .then((response) => {
        if (response.status === 200) {
          setData(response?.data);
          console.log(response?.data[1].title);
        }
      })
      .catch((error) => {
        const isArray = Array.isArray(error.response.data.detail);
        let errorMessage;
        if (isArray) {
          errorMessage = error.response.data.detail[0].msg;
        } else {
          errorMessage = error.response.data.detail;
        }
        if (error.response.status === 403) {
          localStorage.clear();
          navigate(`/`);
        } else if (statusCodeArray.includes(error.response.status)) {
          dispatch(showErrorToast(errorMessage));
        } else {
          dispatch(showErrorToast("Something went wrong"));
        }
      });
  }, []);
  return (
    <>
      {data && (
        <Box pb={3}>
          <Grid container direction="row" columnSpacing={5} rowSpacing={5}>
            {Object?.entries(data)?.map((item, index) => {
              console.log(item);
              return (
                <Grid item lg={3} md={3} sm={12} xs={12} key={index}>
                  <CardStyle
                    sx={{
                      borderLeft: `0.25rem solid ${colorArray[index]}!important`,
                    }}
                    key={index}
                  >
                    <CardContent
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ color: `${colorArray[index]}` }}>
                        <NumberGrid>
                          {`${item[1]?.count}  ${
                            item[1]?.unit !== null ? item[1]?.unit : ""
                          }`}
                        </NumberGrid>
                        {item[1]?.title}
                      </Box>
                    </CardContent>
                  </CardStyle>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      )}
    </>
  );
}

export default SmallWidget;

const CardStyle = styled(Card)`
  height: 110px;
`;

const NumberGrid = styled(Grid)`
  font-size: 1.5rem;
`;
