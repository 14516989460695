import React, { useMemo } from 'react'
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api'
import Loader from '../Loader';


function MapComponent() {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyAYtUpqGU61BcrLbQemCFXPYqPYvmJq6b4",
    })
    return (
        <>
            {isLoaded && <Map />}

        </>
    )
}

function Map() {
    const center = useMemo(() => ({ lat: 44, lng: -80 }), [])
    return (
        <GoogleMap zoom={10} center={center} mapContainerClassName="map-container">
            <Marker position={center} />
        </GoogleMap>
    )
}

export default MapComponent