import { Alert, Snackbar } from '@mui/material';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { closeToast } from '../features/Toaster/toasterSlice';

function Toaster(props) {
    const dispatch = useDispatch();
    const toasterOpen = useSelector(state => state.toaster.ToasterOpen);
    const toasterType = useSelector(state => state.toaster.ToasterType);
    const toasterMessage = useSelector(state => state.toaster.ToasterMessage);
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        dispatch(closeToast());
    };

    return (
        <div>
            <Snackbar
                open={toasterOpen}
                autoHideDuration={3000}
                onClose={handleClose}
                anchorOrigin={{ 'vertical':'top','horizontal': "right" }}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={handleClose}
                    severity={toasterType}
                >
                    {toasterMessage}
                </Alert>
            </Snackbar>
        </div>
    );
}

export default Toaster

