import {
  KeyboardBackspace,
  SaveOutlined,
  SettingsOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { WhiteButton } from "../../components/Buttons";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import {
  showErrorToast,
  showSuccessToast,
} from "../../features/Toaster/toasterSlice";
import axios from "../../api/axios";
import { useLocation, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { LoadingButton } from "@mui/lab";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const statusCodeArray = [401, 403, 404, 409, 422];
const emailRegex = /^(?=.{1,81}$)[\w.-]+@[\w.-]+\.\w{2,4}$/;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

function CreateContact() {
  const locationtoUse = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const navigate = useNavigate();

  const [contactDataToUpdate, setContactDataToUpdate] = useState({
    contactName: "",
    email: "",
    userName: "",
    password: "",
    confirmPassword: "",
    mobileNumber: "",
    role: "",
    companyUuid: "",
  });
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const createContactValidation = (values) => {
    let errors = {};
    if (!values.contactName) {
      errors.contactName = "Required";
    }
    if (!values.email) {
      errors.email = "Required";
    } else if (!emailRegex.test(values.email)) {
      errors.email = "Invalid Email";
    }
    if (locationtoUse.state.recordToUpdate === undefined) {
      if (!values.userName) {
        errors.userName = "Required";
      }
      if (!values.password) {
        errors.password = "Required";
      }
      if (!values.confirmPassword) {
        errors.confirmPassword = "Required";
      } else if (values.password !== values.confirmPassword) {
        errors.confirmPassword = "Password doesnt match with confirm password";
      }
    }
    if (!values.mobileNumber) {
      errors.mobileNumber = "Required";
    } else if (isNaN(values.mobileNumber)) {
      errors.mobileNumber = "Mobile Number should be Number";
    } else if (values.mobileNumber.length !== 10) {
      errors.mobileNumber = "Mobile Number should be of 10 digit";
    }
    if (!values.role) {
      errors.role = "Required";
    }
    if (!values.companyUuid) {
      errors.companyUuid = "Required";
    }
    return errors;
  };

  useEffect(() => {
    if (locationtoUse.state.recordToUpdate !== undefined) {
      axios
        .get(`/contact/${locationtoUse.state.recordToUpdate}`)
        .then((response) => {
          if (response.status === 200 && response.data !== undefined) {
            console.log(response.data)
            setContactDataToUpdate({
              contactName: response.data.contactName,
              email: response.data.email,
              mobileNumber: response.data.mobileNumber,
              role: response.data.roleName.toLowerCase(),
              companyUuid: response.data.id,
            });
          }
        })
        .catch((error) => {
          const isArray = Array.isArray(error.response.data.detail);
          let errorMessage;
          if (isArray) {
            errorMessage = error.response.data.detail[0].msg;
          } else {
            errorMessage = error.response.data.detail;
          }
          if (error.response.status === 403) {
            localStorage.clear();
            navigate(`/`);
          } else if (statusCodeArray.includes(error.response.status)) {
            dispatch(showErrorToast(errorMessage));
          } else {
            dispatch(showErrorToast("Something went wrong"));
          }
        });
    }
  }, [locationtoUse.state.recordToUpdate]);
  const [companyList, setCompanyList] = useState([]);
  const [accountClassification, setAccountClassification] = useState([]);
  useEffect(() => {
    axios.get("/company/dropdown").then((response) => {
      setCompanyList(response.data?.companyNames);
      setAccountClassification(response?.data?.accountClassification);
    });
  }, []);

  const dispatch = useDispatch();
  const createContactOnSubmit = (values, { resetForm }) => {
    setIsSubmittingForm(true);
    if (locationtoUse.state.recordToUpdate === undefined) {
      axios
        .post("/contact", values)
        .then((response) => {
          if (response.status === 201) {
            dispatch(showSuccessToast("Created Contact Successfully"));
            navigate(`/home/contact`);
            resetForm();
          }
        })
        .catch((error) => {
          const isArray = Array.isArray(error.response.data.detail);
          let errorMessage;
          if (isArray) {
            errorMessage = error.response.data.detail[0].msg;
          } else {
            errorMessage = error.response.data.detail;
          }
          if (error.response.status === 403) {
            localStorage.clear();
            navigate(`/`);
          } else if (statusCodeArray.includes(error.response.status)) {
            dispatch(showErrorToast(errorMessage));
          } else {
            dispatch(showErrorToast("Something went wrong"));
          }
        })
        .finally(() => {
          setIsSubmittingForm(false);
        });
    }

    if (locationtoUse.state.recordToUpdate !== undefined) {
      const valuesToSend = {
        id: locationtoUse.state.recordToUpdate,
        contactName: `${values.contactName}`,
        email: `${values.email}`,
        mobileNumber: `${values.mobileNumber}`,
        roleName: `${values.role.toUpperCase()}`,
      };
      axios
        .put("/contact", valuesToSend, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          debugger;
          if (response.status === 204) {
            dispatch(showSuccessToast("Updated Contact Successfully"));
            navigate(`/home/contact`);
          }
        })
        .catch((error) => {
          const isArray = Array.isArray(error.response.data.detail);
          let errorMessage;
          if (isArray) {
            errorMessage = error.response.data.detail[0].msg;
          } else {
            errorMessage = error.response.data.detail;
          }
          if (error.response.status === 403) {
            localStorage.clear();
            navigate(`/`);
          } else if (statusCodeArray.includes(error.response.status)) {
            dispatch(showErrorToast(errorMessage));
          } else {
            dispatch(showErrorToast("Something went wrong"));
          }
        })
        .finally(() => {
          setIsSubmittingForm(false);
        });
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={contactDataToUpdate}
      validate={createContactValidation}
      onSubmit={createContactOnSubmit}
    >
      {(props) => (
        <Box
          pt={3}
          onSubmit={props.handleSubmit}
          onReset={props.handleReset}
          component="form"
          role="form"
        >
          <Card>
            <CardHeader
              title={
                locationtoUse.state.recordToUpdate === undefined
                  ? "Create Contact"
                  : "Edit Contact"
              }
            />
            <Divider />
            <CardContent>
              {locationtoUse.state.recordToUpdate === undefined && (
                <>
                  <Grid container direction="row" columnSpacing={5}>
                    <Grid item lg={4} md={4} sm={6} xs={12}>
                      <FormControl required>
                        <InputLabel
                          variant="standard"
                          id="companyUuid"
                          error={
                            props.touched.companyUuid &&
                            Boolean(props.errors.companyUuid)
                          }
                        >
                          Company Name
                        </InputLabel>
                        <Select
                          labelId="companyUuid"
                          variant="standard"
                          name="companyUuid"
                          MenuProps={MenuProps}
                          {...props.getFieldProps("companyUuid")}
                          error={
                            props.touched.companyUuid &&
                            Boolean(props.errors.companyUuid)
                          }
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {companyList &&
                            companyList.map((item) => (
                              <MenuItem key={item?.id} value={item?.id}>
                                {item?.companyName}
                              </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText
                          variant="standard"
                          error={
                            props.touched.companyUuid &&
                            Boolean(props.errors.companyUuid)
                          }
                        >
                          {props.touched.companyUuid &&
                          Boolean(props.errors.companyUuid)
                            ? props.errors.companyUuid
                            : "Please enter Company Name"}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Divider sx={{ my: 2 }} />
                </>
              )}
              <Grid container direction="row" columnSpacing={5} rowSpacing={3}>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <TextField
                    required
                    label="Contact Name"
                    variant="standard"
                    name="contactName"
                    {...props.getFieldProps("contactName")}
                    error={
                      props.touched.contactName &&
                      Boolean(props.errors.contactName)
                    }
                    helperText={
                      props.touched.contactName &&
                      Boolean(props.errors.contactName)
                        ? props.errors.contactName
                        : "Please enter Contact Name"
                    }
                  />
                </Grid>
              </Grid>
              <Divider sx={{ my: 2 }} />
              <Grid container direction="row" columnSpacing={5} rowSpacing={3}>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <TextField
                    required
                    label="Mobile Number"
                    variant="standard"
                    name="mobileNumber"
                    {...props.getFieldProps("mobileNumber")}
                    error={
                      props.touched.mobileNumber &&
                      Boolean(props.errors.mobileNumber)
                    }
                    helperText={
                      props.touched.mobileNumber &&
                      Boolean(props.errors.mobileNumber)
                        ? props.errors.mobileNumber
                        : "Please enter Mobile Number"
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <TextField
                    required
                    label="Email"
                    variant="standard"
                    name="email"
                    {...props.getFieldProps("email")}
                    error={props.touched.email && Boolean(props.errors.email)}
                    helperText={
                      props.touched.email && Boolean(props.errors.email)
                        ? props.errors.email
                        : "Please enter Email"
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <TextField
                    required
                    multiline
                    label="Username"
                    variant="standard"
                    disabled={
                      locationtoUse.state.recordToUpdate === undefined
                        ? false
                        : true
                    }
                    name="userName"
                    {...props.getFieldProps("userName")}
                    error={
                      props.touched.userName && Boolean(props.errors.userName)
                    }
                    helperText={
                      props.touched.userName && Boolean(props.errors.userName)
                        ? props.errors.userName
                        : "Please enter UserName"
                    }
                  />
                </Grid>
              </Grid>
              <Divider sx={{ my: 2 }} />
              <Grid container direction="row" columnSpacing={5} rowSpacing={3}>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <TextField
                    required
                    label="Password"
                    variant="standard"
                    type={showPassword ? "text" : "password"}
                    disabled={
                      locationtoUse.state.recordToUpdate === undefined
                        ? false
                        : true
                    }
                    name="password"
                    {...props.getFieldProps("password")}
                    error={
                      props.touched.password && Boolean(props.errors.password)
                    }
                    helperText={
                      props.touched.password && Boolean(props.errors.password)
                        ? props.errors.password
                        : "Please enter Password"
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="Show/Hide Password">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <TextField
                    required
                    label="Confirm Password"
                    variant="standard"
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    {...props.getFieldProps("confirmPassword")}
                    disabled={
                      locationtoUse.state.recordToUpdate === undefined
                        ? false
                        : true
                    }
                    error={
                      props.touched.confirmPassword &&
                      Boolean(props.errors.confirmPassword)
                    }
                    helperText={
                      props.touched.confirmPassword &&
                      Boolean(props.errors.confirmPassword)
                        ? props.errors.confirmPassword
                        : "Please enter Confirm Password"
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip title="Show/Hide Password">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowConfirmPassword}
                            >
                              {showConfirmPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Divider sx={{ my: 2 }} />
              <Grid container direction="row" columnSpacing={5} rowSpacing={3}>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <FormControl required>
                    <InputLabel
                      variant="standard"
                      id="role"
                      error={props.touched.role && Boolean(props.errors.role)}
                    >
                      Account Classifications
                    </InputLabel>
                    <Select
                      labelId="role"
                      variant="standard"
                      name="role"
                      MenuProps={MenuProps}
                      {...props.getFieldProps("role")}
                      error={props.touched.role && Boolean(props.errors.role)}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {
                        accountClassification?.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item?.toLowerCase()}>
                              {item?.toUpperCase()}
                            </MenuItem>
                          );
                        })}
                    </Select>
                    <FormHelperText
                      variant="standard"
                      error={props.touched.role && Boolean(props.errors.role)}
                    >
                      {props.touched.role && Boolean(props.errors.role)
                        ? props.errors.role
                        : "Please Select Account Classifications"}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Tooltip title="Back to the Company List">
                <WhiteButton
                  variant="contained"
                  color="inherit"
                  startIcon={<KeyboardBackspace />}
                  onClick={() => {
                    navigate(`/home/contact`);
                  }}
                >
                  Back
                </WhiteButton>
              </Tooltip>
              <Tooltip title="Reset Changes">
                <WhiteButton
                  variant="contained"
                  color="inherit"
                  startIcon={<SettingsOutlined />}
                  type="reset"
                >
                  Reset
                </WhiteButton>
              </Tooltip>
              <Tooltip
                title={
                  locationtoUse.state.recordToUpdate === undefined
                    ? "Click to Save Contact"
                    : "Click to Update Contact"
                }
              >
                <LoadingButton
                  variant="contained"
                  type="submit"
                  color="warning"
                  loading={isSubmittingForm}
                  loadingPosition="start"
                  startIcon={<SaveOutlined />}
                >
                  {locationtoUse.state.recordToUpdate === undefined
                    ? "Save"
                    : "Update"}
                </LoadingButton>
              </Tooltip>
            </CardActions>
          </Card>
        </Box>
      )}
    </Formik>
  );
}

export default CreateContact;
