import {
  ColorLens,
  Image,
  KeyboardBackspace,
  Label,
  SaveOutlined,
  SettingsOutlined,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import { WhiteButton } from "../../components/Buttons";
import {
  showErrorToast,
  showSuccessToast,
} from "../../features/Toaster/toasterSlice";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const statusCodeArray = [401, 403, 404, 409, 422];

const websiteRegex =
  /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
const imageFileRegex = /^image\//; ///(^image)(\/)[a-zA-Z0-9_]*/;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

function CreateCompany() {
  const locationtoUse = useLocation();
  const [industryList, setIndustryList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const navigate = useNavigate();
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [companyDataToUpdate, setCompanyDataToUpdate] = useState({
    company_name: "",
    address: "",
    postcode: "",
    city: "",
    state: "",
    country_uuid: "",
    currency_uuid: "",
    extension: "",
    website: "",
    industry_uuid: "",
    telephone: "",
    registration_code: "",
    company_photo: "",
    company_logo: "",
  });

  const [company_photo_path, setcompany_photo_path] = useState("");
  const [company_logo_path, setcompany_logo_path] = useState("");

  useEffect(() => {
    axios.get("/company/dropdown").then((response) => {
      setIndustryList(response.data?.industryValues);
      setCurrencyList(response.data?.currencyValues);
      setCountryList(response.data?.countryValues);
    });
  }, []);

  useEffect(() => {
    if (locationtoUse.state.recordToUpdate !== undefined) {
      axios
        .get(`/company/${locationtoUse.state.recordToUpdate}`)
        .then((response) => {
          if (response.status === 200 && response.data !== undefined) {
            setCompanyDataToUpdate({
              company_name: response.data.companyName,
              address: response.data.address,
              postcode: response.data.postcode,
              city: response.data.city,
              state: response.data.state,
              country_uuid: response.data.countryUuid,
              currency_uuid: response.data.currencyUuid,
              extension: response.data.extension,
              website: response.data.website,
              industry_uuid: response.data.industryUuid,
              telephone: response.data.telephone,
              registration_code: response.data.registrationCode,
              company_photo: response.data.companyPhotoName,
              company_logo: response.data.companyLogoName,
            });

            setcompany_logo_path(response.data.companyLogoPath);
            setcompany_photo_path(response.data.companyPhotoPath);
          }
        })
        .catch((error) => {
          const isArray = Array.isArray(error.response.data.detail);
          let errorMessage;
          if (isArray) {
            errorMessage = error.response.data.detail[0].msg;
          } else {
            errorMessage = error.response.data.detail;
          }
          if (error.response.status === 403) {
            localStorage.clear();
            navigate(`/`);
          } else if (statusCodeArray.includes(error.response.status)) {
            dispatch(showErrorToast(errorMessage));
          } else {
            dispatch(showErrorToast("Something went wrong"));
          }
        });
    }
  }, [locationtoUse.state.recordToUpdate]);

  const createCompanyValidation = (values) => {
    let errors = {};
    if (!values.company_name) {
      errors.company_name = "Required";
    }
    if (!values.address) {
      errors.address = "Required";
    }

    if (!values.city) {
      errors.city = "Required";
    }

    if (!values.country_uuid) {
      errors.country_uuid = "Required";
    }
    if (!values.currency_uuid) {
      errors.currency_uuid = "Required";
    }

    if (values.extension && isNaN(values.extension)) {
      errors.extension = "Extension should be Number";
    }

    if (values.telephone && isNaN(values.telephone)) {
      errors.telephone = "Telephone should be Number";
    } else if (
      values.telephone &&
      values.telephone.length !== 10 &&
      values.telephone.length > 0
    ) {
      errors.telephone = "Mobile Number should be of 10 digit";
    }

    if (locationtoUse.state.recordToUpdate === undefined) {
      if (
        values.company_logo &&
        !imageFileRegex.test(values.company_logo?.type)
      ) {
        errors.company_logo = "Upload Image File only";
      } else if (values.company_logo?.size / 1024 >= 4000) {
        errors.company_logo = "File Size Should not exceed more than 4 MB";
      }

      if (
        values.company_photo &&
        !imageFileRegex.test(values.company_photo?.type)
      ) {
        errors.company_photo = "Upload Image File only";
      } else if (values.company_photo?.size / 1024 >= 4000) {
        errors.company_photo = "File Size Should not exceed more than 4 MB";
      }
    }
    return errors;
  };
  const dispatch = useDispatch();
  const createConpanyOnSubmit = (values, { resetForm }) => {
    setIsSubmittingForm(true);
    if (values.telephone === "") values.telephone = null;
    if (values.industryList === "") values.telephone = null;

    if (locationtoUse.state.recordToUpdate === undefined) {
      axios
        .post("/company", values, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status === 201) {
            dispatch(showSuccessToast("Created Company Successfully"));
            navigate(`/home/company`);
            resetForm();
          }
        })
        .catch((error) => {
          const isArray = Array.isArray(error.response.data.detail);
          let errorMessage;
          if (isArray) {
            errorMessage = error.response.data.detail[0].msg;
          } else {
            errorMessage = error.response.data.detail;
          }
          if (error.response.status === 403) {
            localStorage.clear();
            navigate(`/`);
          } else if (statusCodeArray.includes(error.response.status)) {
            dispatch(showErrorToast(errorMessage));
          } else {
            dispatch(showErrorToast("Something went wrong"));
          }
        })
        .finally(() => {
          setIsSubmittingForm(false);
        });
    }

    const valuesToSend = {
      countryUuid: `${values.country_uuid}`,
      companyUuid: `${locationtoUse.state.recordToUpdate}`,
      currencyUuid: `${values.currency_uuid}`,
      industryUuid: `${values.industry_uuid}`,
      companyName: `${values.company_name}`,
      address: `${values.address}`,
      postcode: `${values.postcode}`,
      city: `${values.city}`,
      state: `${values.state}`,
      extension: `${values.extension}`,
      website: `${values.website}`,
      telephone: `${values.telephone}`,
      registrationCode: `${values.registration_code}`,
    };
    if (locationtoUse.state.recordToUpdate !== undefined) {
      console.log("put");
      axios
        .put("/company", valuesToSend, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 204) {
            dispatch(showSuccessToast("Updated Company Successfully"));
            navigate(`/home/company`);
          }
        })
        .catch((error) => {
          const isArray = Array.isArray(error.response.data.detail);
          let errorMessage;
          if (isArray) {
            errorMessage = error.response.data.detail[0].msg;
          } else {
            errorMessage = error.response.data.detail;
          }
          if (error.response.status === 403) {
            localStorage.clear();
            navigate(`/`);
          } else if (statusCodeArray.includes(error.response.status)) {
            dispatch(showErrorToast(errorMessage));
          } else {
            dispatch(showErrorToast("Something went wrong"));
          }
        })
        .finally(() => {
          setIsSubmittingForm(false);
        });
    }
  };

  return (
    companyDataToUpdate && (
      <Formik
        enableReinitialize={true}
        initialValues={companyDataToUpdate}
        validate={createCompanyValidation}
        onSubmit={createConpanyOnSubmit}
      >
        {(props) => (
          <Box
            pt={3}
            onSubmit={props.handleSubmit}
            onReset={props.handleReset}
            component="form"
            role="form"
          >
            <Card>
              <CardHeader
                title={
                  locationtoUse.state.recordToUpdate === undefined
                    ? "Create Company"
                    : "Edit Company"
                }
              />
              <Divider />
              <CardContent>
                <Grid container direction="row" columnSpacing={5}>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <TextField
                      required
                      label="Enter Company Name"
                      variant="standard"
                      name="company_name"
                      {...props.getFieldProps("company_name")}
                      error={
                        props.touched.company_name &&
                        Boolean(props.errors.company_name)
                      }
                      helperText={
                        props.touched.company_name &&
                        Boolean(props.errors.company_name)
                          ? props.errors.company_name
                          : "Please enter Company Name"
                      }
                    />
                  </Grid>
                </Grid>
                <Divider sx={{ my: 2 }} />
                <Grid
                  container
                  direction="row"
                  columnSpacing={5}
                  rowSpacing={3}
                >
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <TextField
                      required
                      multiline
                      label="Enter Address"
                      variant="standard"
                      name="address"
                      {...props.getFieldProps("address")}
                      error={
                        props.touched.address && Boolean(props.errors.address)
                      }
                      helperText={
                        props.touched.address && Boolean(props.errors.address)
                          ? props.errors.address
                          : "Please enter Address"
                      }
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <TextField
                      required
                      label="Enter City"
                      variant="standard"
                      name="city"
                      {...props.getFieldProps("city")}
                      error={props.touched.city && Boolean(props.errors.city)}
                      helperText={
                        props.touched.city && Boolean(props.errors.city)
                          ? props.errors.city
                          : "Please enter City No space in between"
                      }
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <TextField
                      // required
                      multiline
                      label="Enter State"
                      variant="standard"
                      name="state"
                      {...props.getFieldProps("state")}
                      error={props.touched.state && Boolean(props.errors.state)}
                      helperText={
                        props.touched.state && Boolean(props.errors.state)
                          ? props.errors.state
                          : "Please enter State"
                      }
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <TextField
                      // required
                      multiline
                      label="Enter Postal Code"
                      variant="standard"
                      name="postcode"
                      {...props.getFieldProps("postcode")}
                      error={
                        props.touched.postcode && Boolean(props.errors.postcode)
                      }
                      helperText={
                        props.touched.postcode && Boolean(props.errors.postcode)
                          ? props.errors.postcode
                          : "Please enter Postal Code"
                      }
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <FormControl required>
                      <InputLabel
                        variant="standard"
                        id="country_uuid "
                        error={
                          props.touched.country_uuid &&
                          Boolean(props.errors.country_uuid)
                        }
                      >
                        Country
                      </InputLabel>
                      <Select
                        labelId="country_uuid "
                        variant="standard"
                        name="country_uuid"
                        MenuProps={MenuProps}
                        {...props.getFieldProps("country_uuid")}
                        error={
                          props.touched.country_uuid &&
                          Boolean(props.errors.country_uuid)
                        }
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {countryList?.map((item) => (
                          <MenuItem
                            key={item?.countryUuid}
                            value={item?.countryUuid}
                          >
                            {item?.countryName} ({item?.countryCode})
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText
                        variant="standard"
                        error={
                          props.touched.country_uuid &&
                          Boolean(props.errors.country_uuid)
                        }
                      >
                        {props.touched.country_uuid &&
                        Boolean(props.errors.country_uuid)
                          ? props.errors.country_uuid
                          : "Please Select Country"}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Divider sx={{ my: 2 }} />
                <Grid
                  container
                  direction="row"
                  columnSpacing={5}
                  rowSpacing={3}
                >
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <TextField
                      // required
                      multiline
                      label="Enter Telephone"
                      variant="standard"
                      name="telephone"
                      {...props.getFieldProps("telephone")}
                      error={
                        props.touched.telephone &&
                        Boolean(props.errors.telephone)
                      }
                      helperText={
                        props.touched.telephone &&
                        Boolean(props.errors.telephone)
                          ? props.errors.telephone
                          : "Please enter Telephone"
                      }
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <TextField
                      // required
                      multiline
                      label="Enter Extension"
                      variant="standard"
                      name="extension"
                      {...props.getFieldProps("extension")}
                      error={
                        props.touched.extension &&
                        Boolean(props.errors.extension)
                      }
                      helperText={
                        props.touched.extension &&
                        Boolean(props.errors.extension)
                          ? props.errors.extension
                          : "Please enter Extension"
                      }
                    />
                  </Grid>
                  {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                      <TextField
                        // required
                        multiline
                        label="Enter Website"
                        variant="standard"
                        name="website"
                        {...props.getFieldProps("website")}
                        error={
                          props.touched.website && Boolean(props.errors.website)
                        }
                        helperText={
                          props.touched.website && Boolean(props.errors.website)
                            ? props.errors.website
                            : "Please enter Website"
                        }
                      />
                    </Grid> */}
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <FormControl
                    // required
                    >
                      <InputLabel
                        variant="standard"
                        id="industry_uuid "
                        error={
                          props.touched.industry_uuid &&
                          Boolean(props.errors.industry_uuid)
                        }
                      >
                        Industry
                      </InputLabel>
                      <Select
                        labelId="industry_uuid "
                        variant="standard"
                        name="industry_uuid"
                        MenuProps={MenuProps}
                        {...props.getFieldProps("industry_uuid")}
                        error={
                          props.touched.industry_uuid &&
                          Boolean(props.errors.industry_uuid)
                        }
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>

                        {industryList?.map((item) => (
                          <MenuItem
                            key={item?.industryUuid}
                            value={item?.industryUuid}
                          >
                            {item?.industryName}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText
                        variant="standard"
                        error={
                          props.touched.industry_uuid &&
                          Boolean(props.errors.industry_uuid)
                        }
                      >
                        {props.touched.industry_uuid &&
                        Boolean(props.errors.industry_uuid)
                          ? props.errors.industry_uuid
                          : "Please Select Industry"}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Divider sx={{ my: 2 }} />
                <Grid
                  container
                  direction="row"
                  columnSpacing={5}
                  rowSpacing={3}
                >
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <FormControl required>
                      <InputLabel
                        variant="standard"
                        id="currency_uuid "
                        error={
                          props.touched.currency_uuid &&
                          Boolean(props.errors.currency_uuid)
                        }
                      >
                        Currency
                      </InputLabel>
                      <Select
                        labelId="currency_uuid "
                        variant="standard"
                        name="currency_uuid"
                        MenuProps={MenuProps}
                        {...props.getFieldProps("currency_uuid")}
                        error={
                          props.touched.currency_uuid &&
                          Boolean(props.errors.currency_uuid)
                        }
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>

                        {currencyList &&
                          currencyList.map((item) => (
                            <MenuItem
                              key={item?.currencyUuid}
                              value={item?.currencyUuid}
                            >
                              {item?.currencyDesc} ({item?.currencyCode})
                            </MenuItem>
                          ))}
                      </Select>
                      <FormHelperText
                        variant="standard"
                        error={
                          props.touched.currency_uuid &&
                          Boolean(props.errors.currency_uuid)
                        }
                      >
                        {props.touched.currency_uuid &&
                        Boolean(props.errors.currency_uuid)
                          ? props.errors.currency_uuid
                          : "Please Select Currency"}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    {locationtoUse.state.recordToUpdate === undefined && (
                      <TextField
                        // required
                        type={"file"}
                        label="Logo Image"
                        variant="standard"
                        name="company_logo"
                        inputProps={{ accept: "image/*" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(event) => {
                          debugger;

                          props.setFieldValue(
                            "company_logo",
                            event.currentTarget.files[0]
                          );
                        }}
                        onBlur={props.handleBlur}
                        error={
                          props.touched.company_logo &&
                          Boolean(props.errors.company_logo)
                        }
                        helperText={
                          props.touched.company_logo &&
                          Boolean(props.errors.company_logo)
                            ? props.errors.company_logo
                            : "Please enter Website"
                        }
                      />
                    )}
                    {locationtoUse.state.recordToUpdate !== undefined && (
                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <FormHelperText>Company Logo</FormHelperText>

                        <img
                          src={company_logo_path}
                          width="auto"
                          height="90px"
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    {locationtoUse.state.recordToUpdate === undefined && (
                      <TextField
                        // required
                        type={"file"}
                        label="Cover Image"
                        variant="standard"
                        name="company_photo"
                        inputProps={{ accept: "image/*" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(event) => {
                          debugger;
                          props.setFieldValue(
                            "company_photo",
                            event.currentTarget.files[0]
                          );
                        }}
                        onBlur={props.handleBlur}
                        error={
                          props.touched.company_photo &&
                          Boolean(props.errors.company_photo)
                        }
                        helperText={
                          props.touched.company_photo &&
                          Boolean(props.errors.company_photo)
                            ? props.errors.company_photo
                            : "Please upload Photo"
                        }
                      />
                    )}
                    {locationtoUse.state.recordToUpdate !== undefined && (
                      <Grid item lg={4} md={4} sm={6} xs={12}>
                        <FormHelperText>Company Photo</FormHelperText>

                        <img
                          src={company_photo_path}
                          width="auto"
                          height="90px"
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Divider sx={{ my: 2 }} />
                <Grid
                  container
                  direction="row"
                  columnSpacing={5}
                  rowSpacing={3}
                >
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <TextField
                      // required
                      multiline
                      label="Enter Registration Code"
                      variant="standard"
                      name="registration_code"
                      {...props.getFieldProps("registration_code")}
                      error={
                        props.touched.registration_code &&
                        Boolean(props.errors.registration_code)
                      }
                      helperText={
                        props.touched.registration_code &&
                        Boolean(props.errors.registration_code)
                          ? props.errors.registration_code
                          : "Please enter Registration Code"
                      }
                    />
                  </Grid>
                </Grid>
                <Divider sx={{ my: 2 }} />
              </CardContent>
              <CardActions>
                <Tooltip title="Back to the Company List">
                  <WhiteButton
                    variant="contained"
                    color="inherit"
                    startIcon={<KeyboardBackspace />}
                    onClick={() => {
                      navigate(`/home/company`);
                    }}
                  >
                    Back
                  </WhiteButton>
                </Tooltip>
                <Tooltip title="Reset">
                  <WhiteButton
                    type="reset"
                    variant="contained"
                    color="inherit"
                    startIcon={<SettingsOutlined />}
                  >
                    Reset
                  </WhiteButton>
                </Tooltip>
                <Tooltip
                  title={
                    locationtoUse.state.recordToUpdate === undefined
                      ? "Click to Save Company"
                      : "Click to Update Company"
                  }
                >
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    color="warning"
                    loading={isSubmittingForm}
                    loadingPosition="start"
                    startIcon={<SaveOutlined />}
                  >
                    {" "}
                    {locationtoUse.state.recordToUpdate === undefined
                      ? "Save"
                      : "Update"}
                  </LoadingButton>
                </Tooltip>
              </CardActions>
            </Card>
          </Box>
        )}
      </Formik>
    )
  );
}

export default CreateCompany;
