import { Box } from '@mui/material';
import React from 'react'
import LoaderSvg from '../common/Loader.svg';

function Loader() {
    return (
        <Box  display={"flex"} justifyContent={"center"} alignContent={"center"}>
            <img src={LoaderSvg} alt="loader" />
        </Box>
    )
}

export default Loader