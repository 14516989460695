import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  styled,
  TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import { showErrorToast } from "../../features/Toaster/toasterSlice";
import Loader from "../../components/Loader";

function ReportBuilder() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [reportRows, setReportRows] = useState([]);
  let [rowsToShow, setRowsToShow] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [reportCols, setReportCols] = useState([]);
  let [filterString, setFilterString] = useState(undefined);

  const statusCodeArray = [401, 403, 404, 409, 422];

  const handleFilterRow = () => {
    setRowsToShow([]);
    return reportRows.filter((item) => {
      let flag = false;
      for (let prop in item) {
        if (
          typeof item[prop] === "string" &&
          item[prop].includes(filterString)
        ) {
          flag = true;
        }
      }
      return flag;
    });
  };

  useEffect(() => {
    setRowsToShow(reportRows);
    let newArray = [];
    if (filterString !== undefined && filterString.trim().length !== 0) {
      newArray = handleFilterRow();
      setRowsToShow(newArray);
    } else if (filterString === "") {
      setRowsToShow(reportRows);
    }
  }, [filterString]);

  useEffect(() => {
    axios
      .get("/report_builder/module_name")
      .then((response) => {
        let histcol = [];
        Object.keys(response?.data[0]).forEach((key) => {
          if (key !== "moduleId" && key !== "moduleName") {
            if (key == "preetyName") {
              histcol.push({
                field: key,
                headerName: "Module Name",
                disableColumnFilter: false,
                disableColumnMenu: true,
                flex: 1,
              });
            } else {
              histcol.push({
                field: key,
                headerName: key,
                disableColumnFilter: false,
                disableColumnMenu: true,
                flex: 1,
              });
            }
          }
        });
        histcol.push({
          field: "Action",
          headerName: "Action",
          width: 150,
          flex: 1,
          renderCell: (cellValues) => {
            return <Box>{cellValues.value}</Box>;
          },
        });
        setReportCols(() => histcol);

        setReportRows(
          response?.data.map((item, index) => {
            return {
              ...item,
              id: index + 1,
              Action: (
                <Box pr={1} pl={1}>
                  <ViewButton
                    variant="contained"
                    sx={{
                      backgroundColor: "white",
                      color: "rgb(237, 100, 55)",
                      borderRadius: "6%",
                      textTransform: "none",
                    }}
                    onClick={() => {
                      navigate(`/home/report-builder-list`, {
                        state: { MoudleId: item.moduleId },
                      });
                      console.log(item.moduleId);
                    }}
                  >
                    Create
                  </ViewButton>
                </Box>
              ),
            };
          })
        );

        setRowsToShow(
          response?.data.map((item, index) => {
            return {
              ...item,
              id: index + 1,
              Action: (
                <Box pr={1} pl={1}>
                  <ViewButton
                    variant="contained"
                    sx={{
                      backgroundColor: "white",
                      color: "rgb(237, 100, 55)",
                      borderRadius: "6%",
                      textTransform: "none",
                    }}
                    onClick={() => {
                      navigate(`/home/report-builder-list`, {
                        state: { MoudleId: item.moduleId },
                      });
                      console.log(item.moduleId);
                    }}
                  >
                    Create
                  </ViewButton>
                </Box>
              ),
            };
          })
        );
      })
      .catch((error) => {
        const isArray = Array.isArray(error.response.data.detail);
        let errorMessage;
        if (isArray) {
          errorMessage = error.response.data.detail[0].msg;
        } else {
          errorMessage = error.response.data.detail;
        }
        if (error.response.status === 403) {
          localStorage.clear();
          navigate(`/`);
        } else if (statusCodeArray.includes(error.response.status)) {
          dispatch(showErrorToast(errorMessage));
        } else {
          dispatch(showErrorToast("Something went wrong.Please try again!!"));
        }
      })
      .finally(() => {
        setLoadingPage(false);
      });
  }, []);

  return (
    <>
      {!loadingPage && (
        <>
          <Box pt={3}>
            <Card>
              <CardHeader title="Report Type" />
              <CardContent>
                <TextField
                  label="Filter"
                  variant="standard"
                  onKeyUp={(e) => {
                    if (
                      e.target.value !== undefined ||
                      e.target.value !== null ||
                      e.target.value !== ""
                    ) {
                      setFilterString(e.target.value);
                      handleFilterRow();
                    }
                  }}
                />
              </CardContent>
            </Card>
          </Box>
          {rowsToShow && reportCols && (
            <Box pt={3}>
              <Card>
                <CardContent>
                  <DataGrid
                    rows={rowsToShow}
                    columns={reportCols}
                    pageSize={20}
                    rowsPerPageOptions={[20]}
                    autoHeight={true}
                    GridAlignment="right"
                  ></DataGrid>
                </CardContent>
              </Card>
            </Box>
          )}
        </>
      )}
      {loadingPage && <Loader />}
    </>
  );
}

export default ReportBuilder;

const ViewButton = styled(Button)`
  &:hover {
    background-color: rgba(0, 0, 0, 0) !important;
  }
`;
