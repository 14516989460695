import React, { useEffect } from 'react'

import { useDispatch } from 'react-redux';
import styled from 'styled-components'
import { Formik } from 'formik';
import { Box, Button, Grid, TextField, Typography, Link as MuiLink, Zoom, Tooltip, IconButton, InputAdornment } from '@mui/material'

import { GradientButton } from '../../components/Buttons';
import { CardWithHeader } from '../../components/Cards'
import { unAuthenticatedAxios } from '../../api/axios';
import { showErrorToast } from '../../features/Toaster/toasterSlice';
import { Link, useNavigate } from 'react-router-dom';
import { login, logout, setUserDetails } from '../../features/User/userSlice';
import { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';


const emailRegex = /^(?=.{1,81}$)[\w.-]+@[\w.-]+\.\w{2,4}$/;
const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;

const loginInitialValues = {
    email: '',
    password: ''
};

const loginValidate = values => {
    let errors = {};
    if (!values.email) {
        errors.email = 'Required'
    }
    else if (!emailRegex.test(values.email)) {
        errors.email = 'Invalid Email'
    }
    if (!values.password) {
        errors.password = 'Required'
    }
    // else if (!passwordRegex.test(values.password)) {
    //     errors.password = 'Invalid Password'
    // }
    return errors
};

function SignInAndSignUp() {
    const [loadingButton, setLoadingButton] = useState(false)
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

    const dispatch = useDispatch();
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        dispatch(logout())
    }, [])

    const navigate = useNavigate()
    const loginOnSubmit = values => {
        setLoadingButton(()=>true);
        unAuthenticatedAxios.post('/auth/login', values)
            .then((response) => {
                debugger
                if (response?.status === 200) {
                    dispatch(login(response?.data?.access_token))
                }
                return response;
            })
            .then(({ status, data }) => {
                if (status == 200) {
                    unAuthenticatedAxios.get('/user/me', {
                        headers: {
                            'Authorization': 'Bearer ' + data.access_token
                        }
                    })
                        .then((response) => {
                            

                            if (response.status == 200 && !data.force_change_password) {
                                dispatch(setUserDetails(response?.data))
                                navigate("/home/dashboard")
                            }
                            else if(response?.status === 200 && data.force_change_password) {
                                    navigate("/changepassword",{
                                        state: { tokenToSend: data.access_token },
                                      })
                                }
                            
                        })
                        .catch((error) => {
                            if (error.response.status === 401) {
                                dispatch(showErrorToast(error.response.data.detail))
                            }
                            else if (error.response.status === 404) {
                                dispatch(showErrorToast(error.response.data.detail))
                            }
                            else if (error.response.status === 422) {
                                dispatch(showErrorToast(error.response.data.detail))
                            }
                            else if (error.response.status === 409) {
                                dispatch(showErrorToast(error.response.data.detail))
                            }
                            else if (error.response.status === 403) {
                                localStorage.clear();
                                navigate(`/`);
                            }
                            else {
                                dispatch(showErrorToast("Something went wrong"))
                            }
                        }).finally(()=>{
                            setLoadingButton(()=>false);
                        })
                }
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    dispatch(showErrorToast(error.response.data.detail))
                }
                else if (error.response.status === 404) {
                    dispatch(showErrorToast(error.response.data.detail))
                }
                else if (error.response.status === 422) {
                    dispatch(showErrorToast(error.response.data.detail))
                }
                else if (error.response.status === 409) {
                    dispatch(showErrorToast(error.response.data.detail))
                }
                else if (error.response.status === 403) {
                    localStorage.clear();
                    navigate(`/`);
                }
                else {
                    dispatch(showErrorToast("Something went wrong"))
                }
            })
            .finally(()=>{
                setLoadingButton(()=>false);
            })
    };
    return (
        <Container>
            <Zoom in timeout={375}>
                <Box px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
                    <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
                        <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
                            <CardWithHeader title={'Sign In'}>
                                <Formik initialValues={loginInitialValues} validate={loginValidate} onSubmit={loginOnSubmit} >
                                    {props => (
                                        <Box pt={4} pb={3} px={3} mt={10} onSubmit={props.handleSubmit} component="form" role="form">
                                            <Box mb={2}>
                                                <TextField
                                                    variant="outlined"
                                                    name='email'
                                                    type= "text"                                                    
                                                    label="Email"
                                                   
                                                    fullWidth
                                                    {...props.getFieldProps('email')}
                                                    error={props.touched.email && Boolean(props.errors.email)}
                                                    helperText={props.touched.email && props.errors.email} />
                                            </Box>
                                            <Box mb={2}>
                                                <TextField
                                                    variant="outlined"
                                                    name='password'
                                                    type={showConfirmPassword ? "text" : "password"}                                                     
                                                    label="Password"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <Tooltip title="Show/Hide Password">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickShowConfirmPassword}
                                                                    >
                                                                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    fullWidth
                                                    {...props.getFieldProps('password')}
                                                    error={props.touched.password && Boolean(props.errors.password)}
                                                    helperText={props.touched.password && props.errors.password} />
                                            </Box>
                                            <Box display="flex" alignItems="center" >
                                                <MuiLink component={Link} to={"/forgotPassword"} underline="hover">
                                                    Forgot Password
                                                </MuiLink>
                                                {/* <FormGroup>
                                                <FormControlLabel control={<Switch/>} label="Remember me" />
                                            </FormGroup> */}
                                            </Box>
                                            <Box mt={4} mb={3}>
                                                <GradientButton loading={loadingButton} loadingIndicator={<Box color={"white"}>Authenticating...</Box>} variant="contained" type="submit" fullWidth>
                                                    sign in
                                                </GradientButton>
                                            </Box>
                                        </Box>
                                    )}
                                </Formik>
                            </CardWithHeader>
                        </Grid>
                    </Grid>
                </Box>
            </Zoom>
        </Container>
    )
}

export default SignInAndSignUp

const Container = styled.main`
    min-height: 100vh;
    position: relative;
    overflow: hidden;
    background-color: rgba(0,0,0,.7);

    &:before{
        background: url('/images/signInBgImage.jpg') center center / cover;
        content:"";
        position: absolute;
        top:0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
    }
`