import { Box, styled } from "@mui/material"


const SubHeaderDivider = styled(Box)`
   background:${props => props.theme.myColors.grey};
   height: 45px;
   border-top:1px solid lightgray;
   border-bottom:1px solid lightgray;
   font-size: 18px;
   display: flex;
   justify-content: center;
   align-items: center;
   color: ${props => props.theme.myColors.TextGrey};
`

export default SubHeaderDivider
