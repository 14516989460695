import { Box, Card, CardContent, CardHeader, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import axios from "../../api/axios";
import { showErrorToast } from "../../features/Toaster/toasterSlice";

const statusCodeArray = [401, 403, 404, 409, 422];

const ViewDevice = () => {
  const dispatch = useDispatch();
  const locationtoUse = useLocation();
  const [data, setData] = useState([]);
  useEffect(() => {
    axios
      .get(`/device/${locationtoUse?.state?.MoudleId}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setData(response?.data);
        }
      })
      .catch((error) => {
        const isArray = Array.isArray(error.response.data.detail);
        let errorMessage;
        if (isArray) {
          errorMessage = error.response.data.detail[0].msg;
        } else {
          errorMessage = error.response.data.detail;
        }
        if (error.response.status === 409) {
          dispatch(showErrorToast(errorMessage));
        }
        if (statusCodeArray.includes(error.response.status)) {
          dispatch(showErrorToast(errorMessage));
        } else {
          dispatch(showErrorToast("Something went wrong"));
        }
      });
  }, []);
  const [siteList, setSiteList] = useState([]);
  useEffect(() => {
    axios.get("/site/dropdown").then((response) => {
      setSiteList(response?.data);
      console.log(siteList);
    });
  }, []);
  function formatPropertyName(propertyName) {
    // Replace underscores with spaces and capitalize each word
    const formattedName = propertyName
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/\b\w/g, (char) => char.toUpperCase());
    return formattedName;
  }
  return (
    <>
      {data && (
        <Box pt={3}>
          <Card>
            <CardHeader title={"Device Details"} />
            <CardContent>
              <Grid container columnSpacing={5}>
                <Box>
                  {Object.entries(data).map(([key, value]) => {
                    return (
                      <Box pl={6} key={key}>
                        {key !== "solarDeviceCapacityUuid" && key !== "id" && (
                          <Grid
                            display={"flex"}
                            justifyContent={"space-between"}
                          >
                            <Box
                              sx={{ fontSize: 18, color: "rgb(237, 100, 55)" }}
                            >
                              {formatPropertyName(key)} :
                            </Box>
                            <Box
                              sx={{
                                fontSize: 18,
                                color: "grey",
                                ml: 2,
                              }}
                            >
                              {key === "siteUuid"
                                ? (
                                    siteList.find(
                                      (item) => item.id === value
                                    ) || {}
                                  ).siteName
                                : value === null
                                ? "N.A"
                                : value}
                            </Box>
                          </Grid>
                        )}
                      </Box>
                    );
                  })}
                </Box>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}
    </>
  );
};

export default ViewDevice;
