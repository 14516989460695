import { DataGrid } from "@mui/x-data-grid";
import axios from "../../api/axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showErrorToast } from "../../features/Toaster/toasterSlice";
import Loader from "../Loader";
import { useNavigate } from "react-router-dom";

function Table({ reportUuid, fetchRealTimeData }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dataRows, setDataRows] = useState([]);
  const [dataCols, setDataCols] = useState([]);
  const statusCodeArray = [401, 403, 404, 409, 422];

  const DisplayTable = () => {
    axios
      .get(`/report/${reportUuid}`)
      .then((response) => {
        let col = [];
        Object.keys(response?.data?.data[0]).forEach((key) => {
          col = response?.data?.columns.map((item) => {
            return {
              field: item.value,
              headerName: item.preety_name,
              flex: 1,
              disableColumnFilter: true,
              disableColumnMenu: true,
              headerClassName: "super-app-theme--header",
            };
          });
        });
        setDataCols(() => col);
        setDataRows(
          response?.data?.data.map((item, index) => {
            return {
              id: index + 1,
              ...item,
            };
          })
        );
      })
      .catch((error) => {
        const isArray = Array.isArray(error?.response?.data?.detail);
        let errorMessage;
        if (isArray) {
          errorMessage = error.response.data.detail[0].msg;
        } else {
          errorMessage = error.response.data.detail;
        }
        if (error.response.status === 403) {
          localStorage.clear();
          navigate(`/`);
        } else if (statusCodeArray.includes(error.response.status)) {
          dispatch(showErrorToast(errorMessage));
        } else {
          dispatch(showErrorToast("Something went wrong.Please try again!!"));
        }
      });
  };

  useEffect(() => {
    DisplayTable();
    if (reportUuid !== undefined && fetchRealTimeData) {
      setInterval(DisplayTable, 5000 * 60);
    }
  }, []);

  return (
    <>
      {dataCols && dataRows && (
        <DataGrid
          rows={dataRows}
          columns={dataCols}
          pageSize={4}
          rowsPerPageOptions={[4]}
          autoHeight={true}
          GridAlignment="right"
        />
      )}
      {!(dataCols || dataRows) && <Loader />}
    </>
  );
}

export default Table;
