import { Box } from "@mui/material";
import React from "react";
import GaugeChart from "react-gauge-chart";

function GuageChart({width,unit,valueInPercent}) {
  return (
    <Box width={width}>
      <GaugeChart
        id="gauge-chart3"
        arcWidth={0.3}
        percent={valueInPercent}
        colors={["#EA4228", "#F5CD19", "#5BE12C"]}
        formatTextValue={(value) => value +' '+ unit }
        textColor={"#646c9a"}
      />
    </Box>
  );
}

export default GuageChart;
