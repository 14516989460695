import axios from "axios";

const baseURL = "https://apiv0.apirodata.io/api/v0/";

export const unAuthenticatedAxios = axios.create({
  baseURL: baseURL,
});

export default axios.create({
  baseURL: baseURL,
});
