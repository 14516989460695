import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Modal,
  styled,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import Loader from "../../components/Loader";
import {
  showErrorToast,
  showSuccessToast,
} from "../../features/Toaster/toasterSlice";

function History() {
  const dispatch = useDispatch();
  const [loginRows, setLoginRows] = useState([]);
  const [loginCols, setLoginCols] = useState(undefined);
  const [historyRows, setHistoryRows] = useState([]);
  const [historyCols, setHistoryCols] = useState(undefined);
  const [isButtonClickedForActivate, setisButtonClickedForActivate] =
    useState(null);
  const [isButtonClickedForDeactivate, setisButtonClickedForDeactivate] =
    useState(null);
  const [email, setEmail] = useState("");
  const [cStatus, setcStatus] = useState(null);
  const [loadingPage, setLoadingPage] = useState(true);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const navigate = useNavigate();
  const handleClose = (flag) => {
    setOpen(false);
    if (typeof flag == "boolean") {
      if (flag) {
        ActivateorDeactivateUser(email, flag);
      }
    }
    setisButtonClickedForDeactivate(null);
    setisButtonClickedForActivate(null);
  };

  const statusCodeArray = [401, 403, 404, 409, 422];

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    p: 4,
  };
  const ActivateorDeactivateUser = (email, isYes) => {
    let valuesToSend = {};
    if (
      (email !== null || email !== undefined || email !== "") &&
      isButtonClickedForActivate &&
      isYes
    ) {
      valuesToSend = {
        email: `${email}`,
        cstatus: cStatus,
      };
    }
    if (
      (email !== null || email !== undefined || email !== "") &&
      isButtonClickedForDeactivate &&
      isYes
    ) {
      valuesToSend = {
        email: `${email}`,
        cstatus: cStatus,
      };
    }
    axios
      .post("/user/activate", valuesToSend, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          GetHistoryData();
          dispatch(showSuccessToast("User Updated Successfully"));
        }
      })
      .catch((error) => {
        const isArray = Array.isArray(error.response.data.detail);
        let errorMessage;
        if (isArray) {
          errorMessage = error.response.data.detail[0].msg;
        } else {
          errorMessage = error.response.data.detail;
        }
        if (error.response.status === 403) {
          localStorage.clear();
          navigate(`/`);
        } else if (statusCodeArray.includes(error.response.status)) {
          dispatch(showErrorToast(errorMessage));
        } else {
          dispatch(showErrorToast("Something went wrong.Please try again!!"));
        }
      })
      .then(() => {
        setisButtonClickedForDeactivate(null);
        setisButtonClickedForActivate(null);
      });
  };

  const GetHistoryData = () => {
    axios
      .get("/user/history")
      .then((response) => {
        debugger;
        let col = [];
        let histcol = [];
        Object.keys(response?.data?.loginActivity[0]).forEach((key) => {
          col.push({
            field: key,
            headerName: key,
            disableColumnFilter: false,
            disableColumnMenu: true,
            flex: 1,
          });
        });
        setLoginCols(() => col);
        setLoginRows(
          response?.data?.loginActivity.map((item, index) => {
            return {
              id: index + 1,
              ...item,
            };
          })
        );

        if (
          response.data.hasOwnProperty("contactDetails") &&
          response?.data?.contactDetails.length > 0
        ) {
          Object.keys(response?.data?.contactDetails[0]).forEach((key) => {
            if (key !== "id" && key !== "isActive") {
              histcol.push({
                field: key,
                headerName: key,
                disableColumnFilter: false,
                disableColumnMenu: true,
                flex: 1,
              });
            }
          });
          histcol.push({
            field: "Action",
            headerName: "Action",
            align: "center",
            width: 150,
            renderCell: (cellValues) => {
              return (
                <Box
                  sx={{
                    textAlign: "center",
                  }}
                >
                  {cellValues.value}
                </Box>
              );
            },
          });
          setHistoryCols(() => histcol);
          setHistoryRows(
            response?.data?.contactDetails.map((item) => {
              return {
                ...item,
                Action: (
                  <Box pr={1} pl={1}>
                    {item.isActive === "False" && (
                      <ActivateButton
                        variant="contained"
                        sx={{
                          backgroundColor: "rgb(35, 183, 229)",
                          borderRadius: "6%",
                        }}
                        onClick={() => {
                          setisButtonClickedForActivate(true);
                          handleOpen();
                          setEmail(item.email);
                          setcStatus(item.isActive === "False" ? false : true);
                        }}
                      >
                        Activate
                      </ActivateButton>
                    )}
                    {item.isActive === "True" && (
                      <DeactivateButton
                        variant="contained"
                        sx={{
                          backgroundColor: "rgb(231, 76, 60)",
                          borderRadius: "6%",
                        }}
                        onClick={() => {
                          setisButtonClickedForDeactivate(true);
                          handleOpen();
                          setEmail(item.email);
                          setcStatus(item.isActive === "False" ? false : true);
                        }}
                      >
                        Deactivate
                      </DeactivateButton>
                    )}
                  </Box>
                ),
              };
            })
          );
        }
      })
      .catch((error) => {
        const isArray = Array.isArray(error.response.data.detail);
        let errorMessage;
        if (isArray) {
          errorMessage = error.response.data.detail[0].msg;
        } else {
          errorMessage = error.response.data.detail;
        }
        if (error.response.status === 403) {
          localStorage.clear();
          navigate(`/`);
        } else if (statusCodeArray.includes(error.response.status)) {
          dispatch(showErrorToast(errorMessage));
        } else {
          dispatch(showErrorToast("Something went wrong.Please try again!!"));
        }
      })
      .finally(() => {
        setLoadingPage(false);
      });
  };
  useEffect(() => {
    GetHistoryData();
  }, []);

  return (
    <>
      {!loadingPage && (
        <>
          <Box pt={3}>
            <Card>
              <CardHeader title="History List" />
              <Divider />
              <CardContent></CardContent>
            </Card>
          </Box>

          <Box pt={3}>
            {loginCols && loginRows && (
              <Card>
                <CardContent sx={{ width: "100%" }}>
                  <DataGrid
                    rows={loginRows}
                    columns={loginCols}
                    pageSize={20}
                    rowsPerPageOptions={[20]}
                    autoHeight={true}
                    GridAlignment="right"
                  />
                </CardContent>
              </Card>
            )}
          </Box>
          <Box pt={3}>
            {historyCols && historyRows && (
              <Card>
                <CardContent sx={{ width: "100%" }}>
                  <DataGrid
                    rows={historyRows}
                    columns={historyCols}
                    pageSize={20}
                    rowsPerPageOptions={[20]}
                    autoHeight={true}
                    GridAlignment="right"
                  />
                </CardContent>
              </Card>
            )}
          </Box>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box p={50} sx={style}>
              <Card>
                <CardContent>
                  <Box textAlign={"center"} mb={2}>
                    Are you sure you want to{" "}
                    {isButtonClickedForActivate ? "Activate" : undefined}
                    {isButtonClickedForDeactivate ? "Deactivate" : undefined}?
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ActionButton
                      variant="contained"
                      sx={{ fontSize: 10, marginRight: 5 }}
                      onClick={() => {
                        handleClose(true);
                      }}
                    >
                      Yes
                    </ActionButton>
                    <ActionButton
                      variant="contained"
                      sx={{ fontSize: 10 }}
                      onClick={() => {
                        handleClose(false);
                      }}
                    >
                      No
                    </ActionButton>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Modal>
        </>
      )}
      {loadingPage && <Loader />}
    </>
  );
}

export default History;

const DeactivateButton = styled(Button)`
  &:hover {
    background-color: rgb(231, 76, 60) !important;
  }
`;

const ActivateButton = styled(Button)`
  &:hover {
    background-color: rgb(35, 183, 229) !important;
  }
`;
const ActionButton = styled(Button)`
  background-color: rgb(35, 183, 229) !important ;
  &:hover {
    background-color: rgb(35, 183, 229) !important;
  }
`;
