import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  styled,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "../../api/axios";
import { useLocation, useNavigate } from "react-router-dom";
import { showErrorToast } from "../../features/Toaster/toasterSlice";
import { useDispatch } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import Loader from "../../components/Loader";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

function ReportBuilderViewDetails() {
  const locationtoUse = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const statusCodeArray = [401, 403, 404, 409, 422];
  const [dropDownData, setDropDownData] = useState([]);
  const [tableName, setTableName] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [reportCols, setReportCols] = useState(null);
  const [reportRows, setReportRows] = useState(null);
  const [loadingPage, setLoadingPage] = useState(true);

  useEffect(() => {
    try {
      if (locationtoUse.state === null) {
        axios.get(`/report_builder/columns_list/1/`).then((response) => {
          setDropDownData(response?.data?.columns);
          setTableName(response?.data?.tableName);
        });
      } else {
        axios
          .get(`/report_builder/columns_list/${locationtoUse.state.MoudleId}/`)
          .then((response) => {
            setDropDownData(response?.data?.columns);
            setTableName(response?.data?.tableName);
          });
      }
    } catch (error) {
      const isArray = Array.isArray(error.response.data.detail);
      let errorMessage;
      if (isArray) {
        errorMessage = error.response.data.detail[0].msg;
      } else {
        errorMessage = error.response.data.detail;
      }
      if (statusCodeArray.includes(error.response.status)) {
        dispatch(showErrorToast(errorMessage));
      } else {
        dispatch(showErrorToast("Something went wrong.Please try again!!"));
      }
    } finally {
      setLoadingPage(false);
    }
  }, []);

  useEffect(() => {
    if (selectedValue.length !== 0) {
      let valuesToSend = {};
      let columsToSend = [];

      dropDownData.forEach((item) => {
        if (selectedValue.includes(item.columnId)) {
          columsToSend.push(item.columnName);
        }
      });

      valuesToSend = {
        columns: columsToSend,
        table: tableName,
      };

      axios
        .post(`/report_builder/fetch_result`, valuesToSend, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          let col = [];
          Object.keys(response?.data[0]).forEach((key) => {
            col.push({
              field: key,
              headerName: key,
              disableColumnFilter: false,
              disableColumnMenu: true,
              flex: 1,
            });
          });
          setReportCols(() => col);
          setReportRows(
            response?.data.map((item, index) => {
              return {
                id: index + 1,
                ...item,
              };
            })
          );
        })
        .catch((error) => {
          const isArray = Array.isArray(error.response.data.detail);
          let errorMessage;
          if (isArray) {
            errorMessage = error.response.data.detail[0].msg;
          } else {
            errorMessage = error.response.data.detail;
          }
          if (error.response.status === 403) {
            localStorage.clear();
            navigate(`/`);
          } else if (statusCodeArray.includes(error.response.status)) {
            dispatch(showErrorToast(errorMessage));
          } else {
            dispatch(showErrorToast("Something went wrong"));
          }
        })
        .finally(() => {
          setLoadingPage(false);
        });
    } else {
      setReportCols(null);
      setReportRows(null);
    }
  }, [selectedValue]);

  return (
    <>
      {!loadingPage && (
        <Box pt={3}>
          <Card>
            <CardContent>
              <Grid container direction="row" columnSpacing={5} rowSpacing={3}>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <FormControl fullWidth required>
                    <InputLabel variant="standard" id="column_list">
                      Columns
                    </InputLabel>

                    <Select
                      multiple={true}
                      name="column_list"
                      labelId="column_list"
                      variant="standard"
                      value={selectedValue}
                      MenuProps={MenuProps}
                      onChange={(event) => {
                        setSelectedValue(event.target.value);
                      }}
                    >
                      {dropDownData &&
                        tableName &&
                        dropDownData.map((column) => (
                          <MenuItem
                            key={column.columnId}
                            value={column.columnId}
                          >
                            <Checkbox
                              checked={
                                dropDownData.indexOf(column.columnId) > -1
                              }
                            />
                            <ListItemText primary={column.preetyName} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                {reportCols && reportRows && (
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <DataGrid
                      rows={reportRows}
                      columns={reportCols}
                      pageSize={20}
                      rowsPerPageOptions={[20]}
                      autoHeight={true}
                      GridAlignment="right"
                    />
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Box>
      )}
      {loadingPage && <Loader />}
    </>
  );
}

export default ReportBuilderViewDetails;

const BackButton = styled(Button)`
  &:hover {
    background-color: white !important;
  }
`;

const ArrowDownwardIconStyling = styled(Button)`
  background-color: ${(props) => props.theme.myColors.orange};
  color: ${(props) => props.theme.myColors.grey};
  &:hover {
    background-color: ${(props) => props.theme.myColors.orange};
  }
`;
