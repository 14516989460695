import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  styled,
  TextField,
  Divider,
  Slide,
} from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch } from "react-redux";
import axios from "../../api/axios";
import {
  ModeEdit as ModeEditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";

import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { DataGrid } from "@mui/x-data-grid";
import {
  showErrorToast,
  showSuccessToast,
} from "../../features/Toaster/toasterSlice";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
const statusCodeArray = [401, 403, 404, 409, 422];

function TableList({ requestUrl, title, submissionRoute, isView = false }) {
  const [loadingPage, setLoadingPage] = useState(true);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  let [rowsToShow, setRowsToShow] = useState([]);
  let [filterString, setFilterString] = useState(undefined);
  const [animationIn, setAnimationIn] = useState(false);
  const navigate = useNavigate();

  const handleDeleteOnClick = (uniqueId) => {
    axios
      .delete(requestUrl, { data: { id: uniqueId } })
      .then((response) => {
        if (response.status === 204) {
          dispatch(showSuccessToast("Record Deleted Successfully!!"));
          window.location.reload();
        }
      })
      .catch((error) => {
        const isArray = Array.isArray(error.response.data.detail);
        let errorMessage;
        if (isArray) {
          errorMessage = error.response.data.detail[0].msg;
        } else {
          errorMessage = error.response.data.detail;
        }

        if (error.response.status === 403) {
          localStorage.clear();
          navigate(`/`);
        } else if (statusCodeArray.includes(error.response.status)) {
          dispatch(showErrorToast(errorMessage));
        } else {
          dispatch(showErrorToast("Something went wrong.Please try again!!"));
        }
      });
  };

  const handleEditOnClick = (uniqueId) => {
    navigate(`/home${requestUrl}/create`, {
      state: { recordToUpdate: uniqueId },
    });
  };

  const handleViewOnClick = (item) => {
    let uniqueId = item.id;
    if (uniqueId)
      navigate(`/home/device/view`, { state: { MoudleId: uniqueId } });
  };

  const handleFilterRow = () => {
    setRowsToShow([]);
    return rows.filter((item) => {
      let flag = false;
      for (let prop in item) {
        if (
          prop !== "id" &&
          typeof item[prop] === "string" &&
          item[prop].includes(filterString)
        ) {
          flag = true;
        }
      }
      return flag;
    });
  };

  useEffect(() => {
    setRowsToShow(rows);
    let newArray = [];
    if (filterString !== undefined && filterString.trim().length !== 0) {
      newArray = handleFilterRow();
      setRowsToShow(newArray);
    } else if (filterString === "") {
      setRowsToShow(rows);
    }
  }, [filterString]);

  const dispatch = useDispatch();
  useEffect(() => {
    setAnimationIn(false);
    setColumns([]);
    setRows([]);
    setRowsToShow([]);
    axios
      .get(requestUrl + "/")
      .then((response) => {
        filterString = "";
        let col = [];
        col = response?.data?.columns
          .map((item) => {
            if (item.value !== "id") {
              return {
                field: item.value,
                headerName: item.preety_name,
                minWidth: 250,

                disableColumnFilter: true,
                disableColumnMenu: true,
                headerClassName: "super-app-theme--header",
              };
            }
          })
          .filter((item) => item);
        col.push({
          field: "Action",
          headerName: "Action",
          renderCell: (cellValues) => {
            return (
              <Box
                sx={{
                  textAlign: "center",
                }}
              >
                {cellValues.value}
              </Box>
            );
          },
          minWidth: 250,
          disableColumnFilter: false,
          disableColumnMenu: true,
        });
        setColumns(() => col);
        const rowsFromResponse = response?.data?.data.map((item) => {
          return {
            ...item,
            Action: (
              <>
                <IconButton onClick={() => handleEditOnClick(item.id)}>
                  <ModeEditIcon color="primary" />
                </IconButton>
                <IconButton onClick={() => handleDeleteOnClick(item.id)}>
                  <DeleteIcon color="error" />
                </IconButton>
                {isView && (
                  <IconButton onClick={() => handleViewOnClick(item)}>
                    <VisibilityIcon color="primary" />
                  </IconButton>
                )}
              </>
            ),
          };
        });
        setRows(rowsFromResponse);
        setRowsToShow(rowsFromResponse);
      })
      .catch((error) => {
        const isArray = Array.isArray(error.response.data.detail);
        let errorMessage;
        if (isArray) {
          errorMessage = error.response.data.detail[0].msg;
        } else {
          errorMessage = error.response.data.detail;
        }
        if (error.response.status === 403) {
          localStorage.clear();
          navigate(`/`);
        } else if (statusCodeArray.includes(error.response.status)) {
          dispatch(showErrorToast(errorMessage));
        } else {
          dispatch(showErrorToast("Something went wrong.Please try again!!"));
        }
      })
      .then(() => {
        setLoadingPage(false);
      })
      .then(() => {
        setTimeout(() => {
          setAnimationIn(true);
        }, 375);
      });
  }, [requestUrl]);

  return (
    <>
      {!loadingPage && (
        <>
          <Slide direction="down" in={animationIn} timeout={375}>
            <Box pt={3} pl={3} pr={3} pb={3}>
              <Card>
                <CardHeader
                  title={title}
                  action={
                    <IconButton
                      onClick={() => {
                        navigate(`/home${requestUrl}/create`, {
                          state: { recordToUpdate: undefined },
                        });
                      }}
                    >
                      <AddIconStyling />
                    </IconButton>
                  }
                ></CardHeader>
                <Divider sx={{ my: 2 }} />

                <CardContent>
                  <TextField
                    label="Filter"
                    variant="standard"
                    onKeyUp={(e) => {
                      if (
                        e.target.value !== undefined ||
                        e.target.value !== null ||
                        e.target.value !== ""
                      ) {
                        setFilterString(e.target.value);
                        handleFilterRow();
                      }
                    }}
                  />
                </CardContent>
              </Card>
            </Box>
          </Slide>
          {rowsToShow && columns && (
            <Slide direction="down" in={animationIn} timeout={750}>
              <Box pt={3} pl={3} pr={3} pb={3}>
                <Card>
                  <CardContent sx={{ width: "100%" }}>
                    <DataGrid
                      rows={rowsToShow}
                      columns={columns}
                      pageSize={20}
                      rowsPerPageOptions={[20]}
                      autoHeight={true}
                      GridAlignment="right"
                    />
                  </CardContent>
                </Card>
              </Box>
            </Slide>
          )}
        </>
      )}
      {loadingPage && <Loader />}
    </>
  );
}
export default TableList;

const AddIconStyling = styled(AddIcon)`
  background-color: ${(props) => props.theme.myColors.orange};
  border-radius: 10%;
  color: ${(props) => props.theme.myColors.grey};
  width: 3rem;
  height: 2rem;
`;
