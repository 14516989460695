import { Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import axios from "../../api/axios";
import { showErrorToast } from "../../features/Toaster/toasterSlice";
import BatteryGuage from "./BatteryGuage";
import GuageChart from "./GuageChart";
import Loader from "../Loader";
import { useNavigate } from "react-router-dom";

function ClientDashboardWidget({ reportUuid }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const statusCodeArray = [401, 403, 404, 409, 422];
  const [data, setData] = useState(null);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    axios
      .get(`/report/${reportUuid}`)
      .then((response) => {
        debugger;
        if (response.status == 200) setData(response?.data?.data);
      })
      .catch((error) => {
        const isArray = Array.isArray(error.response.data.detail);
        let errorMessage;
        if (isArray) {
          errorMessage = error.response.data.detail[0].msg;
        } else {
          errorMessage = error.response.data.detail;
        }
        if (error.response.status === 403) {
          localStorage.clear();
          navigate(`/`);
        } else if (statusCodeArray.includes(error.response.status)) {
          dispatch(showErrorToast(errorMessage));
        } else {
          dispatch(showErrorToast("Something went wrong.Please try again!!"));
        }
      })
      .catch(() => {
        dispatch(showErrorToast("Something went wrong.Please try again!!"));
      })
      .finally(() => setLoader(false));
  }, []);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        data && (
          <Paper
            sx={{ overflow: "auto", padding: 2, marginBottom: 2 }}
            elevation={3}
            variant="elevation"
          >
            <Grid container direction="row" columnSpacing={5} rowSpacing={5}>
              <Grid item lg={3} md={6} sm={6} xs={12}>
                <Typography variant="h6" align="center">
                  <b>Site Details:</b>
                </Typography>
                <Box
                  height={100}
                  display={"flex"}
                  flexWrap="wrap"
                  alignItems={"flex-start"}
                  alignContent={"center"}
                  flexDirection={"column"}
                  justifyContent="space-around"
                >
                  <Typography variant="body1">
                    {" "}
                    <b>SiteName:</b> {data.sitename}
                  </Typography>
                  <Typography variant="body1">
                    {" "}
                    <b>Address:</b> {data.address}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={3} md={6} sm={6} xs={12}>
                <Typography mb={1} variant="h6" align="center">
                  <b>Generation Power:</b>
                </Typography>
                <Box
                  height={100}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent="center"
                >
                  <GuageChart
                    unit={"W"}
                    valueInPercent={data.generationpower / 100}
                    width={240}
                  />
                </Box>
              </Grid>
              <Grid item lg={3} md={6} sm={6} xs={12}>
                <Typography mb={1} variant="h6" align="center">
                  <b>Battery SOC:</b>
                </Typography>
                <Box
                  height={100}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent="center"
                >
                  <BatteryGuage height={80} value={data.batterysoc} />
                </Box>
              </Grid>
              <Grid item lg={3} md={6} sm={6} xs={12}>
                <Typography mb={1} variant="h6" align="center">
                  <b>Energy Generated:</b>
                </Typography>
                <Box
                  height={100}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent="center"
                >
                  <GuageChart
                    unit={"Kwh"}
                    valueInPercent={data.energygeneratedtoday / 100}
                    width={240}
                  />
                </Box>
              </Grid>
            </Grid>
          </Paper>
        )
      )}
    </>
  );
}

export default ClientDashboardWidget;
