import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  styled,
  Tooltip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "../../api/axios";
import { showErrorToast } from "../../features/Toaster/toasterSlice";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { CSVLink } from "react-csv";
import Loader from "../../components/Loader";

function GlobalReportViewDetails() {
  const locationtoUse = useLocation();
  const dispatch = useDispatch();
  const [dataRows, setDataRows] = useState([]);
  const [dataCols, setDataCols] = useState([]);
  const navigate = useNavigate();
  const [loadingPage, setLoadingPage] = useState(true);

  const statusCodeArray = [401, 403, 404, 409, 422];

  useEffect(() => {
    debugger;
    if (locationtoUse.state.MoudleId !== undefined) {
      axios
        .get(`/report/${locationtoUse.state.MoudleId}`)
        .then((response) => {
          console.log(response);
          let col = [];
          Object.keys(response?.data?.data[0]).forEach((key) => {
            col.push({
              field: key,
              headerName: key,
              disableColumnFilter: false,
              disableColumnMenu: true,
              minWidth: 250,
            });
          });
          setDataCols(() => col);
          setDataRows(
            response?.data?.data.map((item, index) => {
              return {
                id: index + 1,
                ...item,
              };
            })
          );
        })
        .catch((error) => {
          const isArray = Array.isArray(error.response.data.detail);
          let errorMessage;
          if (isArray) {
            errorMessage = error.response.data.detail[0].msg;
          } else {
            errorMessage = error.response.data.detail;
          }
          if (error.response.status === 403) {
            localStorage.clear();
            navigate(`/`);
          } else if (statusCodeArray.includes(error.response.status)) {
            dispatch(showErrorToast(errorMessage));
          } else {
            dispatch(showErrorToast("Something went wrong.Please try again!!"));
          }
        })
        .finally(() => {
          setLoadingPage(false);
        });
    }
  }, []);

  return (
    <>
      {!loadingPage && (
        <>
          <Box pt={3}>
            <Card>
              <CardHeader
                title="Device's"
                action={
                  <CSVLink
                    data={dataRows}
                    filename={`Global-Report-${Date()}.csv`}
                  >
                    <Tooltip title="Export data to CSV">
                      <ArrowDownwardIconStyling>
                        <ArrowDownwardIcon />
                      </ArrowDownwardIconStyling>
                    </Tooltip>
                  </CSVLink>
                }
              />
              <Divider />
              <CardContent></CardContent>
            </Card>
          </Box>

          <Box pt={3}>
            {dataCols && dataRows && (
              <Card>
                <CardContent sx={{ width: "100%" }}>
                  <DataGrid
                    rows={dataRows}
                    columns={dataCols}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    autoHeight={true}
                    GridAlignment="right"
                  />
                </CardContent>
                <CardContent>
                  <BackButton
                    variant="contained"
                    sx={{ color: "rgba(0,0,0,.87)", backgroundColor: "white" }}
                    mt={10}
                    onClick={() => {
                      navigate(`/home/global-report`);
                    }}
                  >
                    <KeyboardBackspaceIcon />
                    Back
                  </BackButton>
                </CardContent>
              </Card>
            )}
          </Box>
        </>
      )}
      {loadingPage && <Loader />}
    </>
  );
}

export default GlobalReportViewDetails;

const BackButton = styled(Button)`
  &:hover {
    background-color: white !important;
  }
`;

const ArrowDownwardIconStyling = styled(Button)`
  background-color: ${(props) => props.theme.myColors.orange};
  color: ${(props) => props.theme.myColors.grey};
  &:hover {
    background-color: ${(props) => props.theme.myColors.orange};
  }
`;
